/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { isArray, isNumber } from "lodash";
import getApiData from "../../../lib/getApiData";
import Button from "../../htmlElements/button";
import InputFieldsHelper, {
  getSelectedValues,
} from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { AuthenticationState } from "../../../context/AuthenticationContext";

import {
  DictionaryDataState,
  DictionaryDataDispatch,
} from "../../../context/DictionaryDataContext";

import SelectField from "./selectField";
import { createDropdownList } from "../steps/createDropdownList";
import { getProjectName } from "../../../lib/functions";

//---------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------

interface NewOtherFormProps {
  title: string;
  fieldName: string;
  inputName: string;
  triggerSaveMessageHandler: any;
  referenceNumber?: any;
  acknowledgementNumber?: any;
  materialSettings?: any;
  doiField?: boolean;
  dictionaryStateName: string;
  showParent?: boolean;
  options?: any;
}

export default function NewOtherForm(props: NewOtherFormProps) {
  const submissionState: any = useContext(SubmissionState) || {};
  const submissionDispatch = useContext(SubmissionDispatch) || "";
  const authenticationState = useContext(AuthenticationState) || "";

  const dictionaryDataState: any = useContext(DictionaryDataState) || [];
  const dictionaryDataDispatch = useContext(DictionaryDataDispatch) || [];

  const projectName = getProjectName(true);

  const {
    title,
    fieldName: name,
    inputName,
    triggerSaveMessageHandler,
    referenceNumber,
    acknowledgementNumber,
    materialSettings,
    doiField,
    dictionaryStateName,
    showParent,
    options,
  } = props;

  const defaultButtonText = `Add new ${title}`;
  const loadingButtonText = `Please wait, adding ${title}`;

  const fieldValues = InputFieldsHelper.getFieldValues({
    referenceNumber,
    acknowledgementNumber,
    name,
    submissionState,
    projectName,
  });

  const onChangeHandler = InputFieldsHelper.getOnChangeHandler({
    referenceNumber,
    acknowledgementNumber,
    name,
    projectName,
  });

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  const disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField,
  });

  const [formVisibilityStatus, setFormVisibilityStatus] = useState(false);
  const [newInputValue, setNewInputValue] = useState("");
  const [newParentValue, setNewParentValue] = useState("");
  const [newInputButtonClasses, setInputButtonClasses] = useState("");
  const [optionAddedMessage, setOptionAddedMessage] = useState("");
  const [newAuthorButtonText, setAuthorButtonText] = useState(defaultButtonText); // eslint-disable-line prettier/prettier
  const [newInputButtonDisabledState, setAuthorButtonDisabledState] = useState(false); // eslint-disable-line prettier/prettier

  const addNewValueHandler = async () => {
    setAuthorButtonText(loadingButtonText);
    setInputButtonClasses("waitingState");
    setAuthorButtonDisabledState(true);

    const existingLabels = dictionaryDataState[dictionaryStateName].map(
      (item: any) => {
        return {
          label: item.label,
          materialId: item.materialId,
          value: item.value,
          status: item.status === undefined ? "APPROVED" : item.status,
        };
      }
    );

    // Find if we have an existing value in the DD table
    const findExistingValue = existingLabels.filter(
      (item: any) => item.label.toLowerCase() === newInputValue.toLowerCase()
    );

    let existingValue: any = [];

    if (findExistingValue && findExistingValue.length > 0) {
      existingValue = findExistingValue.reduce((item: any) => item);
    }

    // handle APPROVED item
    if (existingValue && existingValue.status === "APPROVED") {
      setOptionAddedMessage(
        "The value already exists. Please select it from the dropdown menu."
      );
      setNewInputValue("");
      setInputButtonClasses("");
      setAuthorButtonDisabledState(false);
      setAuthorButtonText(defaultButtonText);
      setFormVisibilityStatus(false);
      return;
    }

    // Initialize the `materialIDs` array with current object ID
    const materialIDs: any = [submissionState.objectId];

    // Create parameters for adding the new DD item (default is POST (add))
    const params = {
      endpoint: `dd/item/${dictionaryStateName}`,
      method: "post",
      params: {
        item: {
          level: newParentValue.value ? "02" : "01",
          label: newInputValue,
          status: "NEW",
          materialId: [],
          parent: newParentValue.value,
          projectName,
        },
      },
      headers: {
        "X-Amz-Security-Token": authenticationState.token,
      },
      debug: false,
    };

    if (findExistingValue && findExistingValue.length > 0) {
      // If there is already an existing value, we change the `method` to `put` [update] and
      // add the existing materialID's to the `materialIDs` array

      const existingValue = findExistingValue.reduce((item: any) => item);

      if (isArray(existingValue.materialId)) {
        existingValue.materialId.forEach((materialId: any) => {
          materialIDs.push(materialId);
        });
      } else {
        materialIDs.push(existingValue.materialId);
      }

      params.endpoint = `${params.endpoint}/${existingValue.value}`;
      params.method = "put";
      params.params.item = { ...existingValue };
    }

    // Update materialID
    params.params.item.materialId = materialIDs;

    // Add new DD item
    await getApiData(params).then(async (apiResponse) => {
      // Get all DD items for category again
      await getApiData({
        endpoint: `dd/items/${dictionaryStateName}`,
      }).then(async (res) => {
        await createDropdownList(res.data).then((dropdownList: any) => {
          // Save the new array to dictionaryState
          dictionaryDataDispatch({
            type: "UPDATE_STATE",
            dictionaryData: {
              [dictionaryStateName]: dropdownList,
            },
          });

          const newOptionParams = {
            type: "DD",
            value: apiResponse.data.id,
            label: newInputValue,
          };

          // Get current selected options
          const selectedOptions: any = fieldValues;

          // Add the new option to the selected once
          selectedOptions.push(newOptionParams);

          // Add `parents` too to the selected options
          dropdownList
            .filter(
              (dropdownListItem: any) =>
                dropdownListItem.id === apiResponse.data.id
            )
            .map((dropdownListItem: any) => {
              if (dropdownListItem.parent !== undefined) {
                // Make sure we don't add the parent when it's already in the list
                if (
                  !selectedOptions
                    .map((item: any) => item.value)
                    .includes(dropdownListItem.parent)
                ) {
                  const parentItem = {
                    type: "DD",
                    value: dropdownListItem.parent,
                  };

                  selectedOptions.push(parentItem);
                }
              }
              return true;
            });

          // Save selected options to submissionState
          submissionDispatch({
            type: onChangeHandler,
            value: selectedOptions,
            fieldName: name,
            referenceNumber,
            acknowledgementNumber,
          });

          setTimeout(() => {
            triggerSaveMessageHandler();

            setNewInputValue("");
            setInputButtonClasses("");
            setAuthorButtonDisabledState(false);
            setAuthorButtonText(defaultButtonText);
            setFormVisibilityStatus(false);
          }, 500);
        });
      });
    });
  };

  const displayOptionAddedMessage = optionAddedMessage ? (
    <span className="block">{optionAddedMessage}</span>
  ) : (
    ""
  );

  return disabled ? (
    ""
  ) : (
    <>
      <div
        className={clsx(
          "col-span-12 text-left",
          !formVisibilityStatus ? "" : "hidden"
        )}
      >
        {displayOptionAddedMessage}
        <Button
          innerContent={`➕ ${defaultButtonText}`}
          textSize="xs"
          paddingClasses="py-1 px-2"
          color="blue"
          onClick={() => {
            setFormVisibilityStatus(true);
            setOptionAddedMessage("");
          }}
        />
      </div>

      <div className={!formVisibilityStatus ? "hidden" : ""}>
        <div className="p-2">Or add a new {title}:</div>
        <div
          className={clsx(
            "grid grid-cols-8 gap-2 relative",
            newInputButtonClasses
          )}
        >
          <div className="overlay" />

          <div className="col-span-6">
            <input
              type="text"
              key={inputName}
              name={inputName}
              placeholder={`New ${title}`}
              disabled={newInputButtonDisabledState}
              className={clsx(
                "w-full inline css-1s2u09g-control css-13cymwt-control px-2",
                newInputButtonClasses
              )}
              value={newInputValue}
              onChange={(e) => {
                setNewInputValue(e.target.value);
              }}
            />
          </div>

          {showParent ? (
            <div className="col-span-6">
              <SelectField
                key={`${inputName}_parent`}
                name={`${inputName}_parent`}
                options={options.map((item: any) => {
                  const indent = "\xa0".repeat(item.depth * 4);
                  return {
                    value: item.value,
                    label: `${indent} ${item.label}`,
                  };
                })}
                zIndex={450}
                materialSettings={materialSettings}
                placeholder="If applicable, please select a higher level label for this new label to sit under"
                customChangeHandler={(selectedOption: any) => {
                  setNewParentValue(selectedOption);
                }}
                customFieldValues={newParentValue}
                isMulti={false}
              />
            </div>
          ) : (
            ""
          )}

          <div className="col-span-2 text-right">
            <Button
              color="blue"
              textSize="xs"
              onClick={addNewValueHandler}
              additionalClasses={clsx(
                "newInputButton w-full",
                newInputButtonClasses
              )}
              disabled={newInputButtonDisabledState}
            >
              {newAuthorButtonText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

NewOtherForm.defaultProps = {
  doiField: false,
};
