import { isArray, isEmpty } from "lodash";
import OasisMapping from "../../../oasisMapping.json";
import { step7options } from "../../data/step7options";

interface OasisMetadataReverseFormatterProps {
  dictionaryData: Record<string, any[]>; // Assuming dictionary data is an object of arrays
  metadata: { data: Record<string, any> }; // Adjust this based on the `metadata` structure
}

function lookupDictionaryData(
  dictionaryData: Record<string, any[]>,
  ddCategoryKey: string,
  selectedValues: string[]
) {
  return (
    dictionaryData[ddCategoryKey]?.filter((item) =>
      selectedValues.includes(item.id)
    ) || []
  );
}

function getSelectedValues(fieldData: any[]): string[] {
  return Array.isArray(fieldData)
    ? fieldData
        .filter((item) => !isEmpty(item))
        .map((item) => item.value)
        .filter(Boolean)
    : [];
}

function processFieldData(
  partOf: string,
  fieldName: string,
  dictionaryData: Record<string, any[]>,
  ddCategoryKey: string,
  convertedMetadata: Record<string, any>
) {
  const fieldData =
    partOf === "publication"
      ? convertedMetadata[partOf]?.[0]?.[fieldName]
      : convertedMetadata[partOf]?.[fieldName];

  if (Array.isArray(fieldData)) {
    const selectedValues = getSelectedValues(fieldData);
    const dictionaryValues = lookupDictionaryData(
      dictionaryData,
      ddCategoryKey,
      selectedValues
    );

    // if (fieldName === "publicationAuthor") {
    //   console.log("🚀 ~ ddCategoryKey:", ddCategoryKey)
    //   console.log("🚀 ~ dictionaryData:", dictionaryData[ddCategoryKey])
    //   console.log("🚀 ~ dictionaryValues:", dictionaryValues)
    //   console.log("🚀 ~ partOf:", partOf)
    //   console.log("🚀 ~ fieldName:", fieldName)
    //   console.log("🚀 ~ ...:", convertedMetadata[partOf][0][fieldName])
    // }

    if (partOf === "publication") {
      convertedMetadata[partOf][0][fieldName] = dictionaryValues;
    } else {
      convertedMetadata[partOf][fieldName] = dictionaryValues;
    }
  }
}

const oasisMetadataReverseFormatter = ({
  dictionaryData,
  metadata,
}: OasisMetadataReverseFormatterProps) => {
  const convertedMetadata = metadata.data || [];

  try {
    if (metadata) {
      const ddFields = OasisMapping.filter(
        (item) => item.isKeywordSearch && item.ddCategoryKey
      );

      ddFields.forEach(({ partOf, fieldName, ddCategoryKey }) => {
        //

        // if (fieldName === "publicationAuthor") {
        //   console.log("🚀 ~ ddFields.forEach ~ partOf:", partOf)
        //   console.log("🚀 ~ ddFields.forEach ~ fieldName:", fieldName)
        //   console.log("🚀 ~ ddFields.forEach ~ ddCategoryKey:", ddCategoryKey)
        //   console.log("🚀 ~ ddFields.forEach ~ convertedMetadata:", convertedMetadata)
        //   console.log("🚀 ~ ddFields.forEach ~ convertedMetadata:", convertedMetadata.publication[0].publicationAuthor)
        // }

        if (partOf && fieldName && ddCategoryKey) {
          processFieldData(
            partOf,
            fieldName,
            dictionaryData,
            ddCategoryKey,
            convertedMetadata
          );
        }
      });
    }
  } catch (error) {
    console.error("Error in oasisMetadataReverseFormatter:", error);
  }

  if (
    convertedMetadata.publication &&
    convertedMetadata.publication.length > 0
  ) {
    convertedMetadata.publication[0].publicationType = ["Journal Article"];

    if (isArray(convertedMetadata.publication[0].publicationDate)) {
      if (convertedMetadata.publication[0].publicationDate[0].type === "DD") {
        convertedMetadata.publication[0].publicationDate = {
          value: convertedMetadata.publication[0].publicationDate[0].value,
          label: convertedMetadata.publication[0].publicationDate[0].value,
        };
      } else {
        convertedMetadata.publication[0].publicationDate =
          convertedMetadata.publication[0].publicationDate[0];
      }
    }

    if (
      convertedMetadata.publication[0].summaryOriginalArticleOpenAccess ===
      "true"
    ) {
      convertedMetadata.publication[0].summaryOriginalArticleOpenAccess = {
        label: "Yes",
        value: "true",
      };
    } else {
      convertedMetadata.publication[0].summaryOriginalArticleOpenAccess = {
        label: "No",
        value: "false",
      };
    }
  }

  if (convertedMetadata.settings) {
    if (convertedMetadata.settings.settingsLicenceAgreement === "true") {
      convertedMetadata.settings.settingsLicenceAgreement = {
        label:
          "I accept that the OASIS summary will be published under a CC BY-NC-SA license",
        value: "true",
      };
    } else {
      convertedMetadata.settings.settingsLicenceAgreement = {
        label:
          "I don't accept that the OASIS summary will be published under a CC BY-NC-SA license",
        value: "false",
      };
    }

    if (convertedMetadata.settings.settingsTermsAndConditions === "true") {
      convertedMetadata.settings.settingsTermsAndConditions = {
        label: "Yes, I agree to the terms and conditions.",
        value: "true",
      };
    } else {
      convertedMetadata.settings.settingsTermsAndConditions = {
        label: "No, I don't agree to the terms and conditions.",
        value: "false",
      };
    }
  }

  // console.debug("Converted Metadata:", JSON.stringify(convertedMetadata));

  return convertedMetadata;
};

export default oasisMetadataReverseFormatter;
