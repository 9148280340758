import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export default function OasisStep0() {
  let introText = "";

  try {
    const query = useStaticQuery(graphql`
      query {
        oasisText: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-submission-step-0" } }
        ) {
          html
        }
      }
    `);

    introText = query.oasisText.html;
  } catch (error) {
    console.log("error:", error);
  }

  return introText ? (
    <div
      className="markdownText"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: introText,
      }}
    />
  ) : null;
}
