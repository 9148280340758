import React, { useContext } from "react";

import { isEmpty } from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import SelectField from "../../fields/selectField";
import { infoBoxesContent } from "../../infoBoxesContent";
import InputWrapper from "../../inputWrapper";

import JournalFields from "../journal-fields";
import BookFields from "../book-fields";
import ChapterFields from "../chapter-fields";
import ProceedingsFields from "../proceedings-fields";
import ThesisFields from "../thesis-fields";
import OtherFields from "../other-fields";
import Button from "../../../htmlElements/button";
import H2 from "../../../htmlElements/h2";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../../context/SubmissionContext";

function PublicationTypeForm(props: any) {
  const submissionState = useContext(SubmissionState) || {
    acknowledgement: [],
  };

  const { acknowledgementNumber, triggerSaveMessageHandler } = props;

  const { acknowledgement } = submissionState;

  switch (
    acknowledgement[acknowledgementNumber]?.publication?.publicationType?.label
  ) {
    case "Journal Article":
      return (
        <JournalFields
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      );
    case "Book":
      return (
        <BookFields
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      );
    case "Chapter":
      return (
        <ChapterFields
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      );
    case "Proceedings":
      return (
        <ProceedingsFields
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      );
    case "Thesis":
      return (
        <ThesisFields
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      );
    case "Other":
      return (
        <OtherFields
          acknowledgementNumber={acknowledgementNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      );
    default:
      return <></>;
  }
}

interface PageProps {
  triggerSaveMessageHandler: any;
}

const TypeDropdown = (props: PageProps) => {
  const submissionState = useContext(SubmissionState) || {
    acknowledgement: [],
  };

  const { triggerSaveMessageHandler } = props;

  return submissionState.acknowledgement.map(
    (acknowledgement: any, acknowledgementNumber: number) => {
      const title = `Acknowledgement ${
        acknowledgementNumber ? acknowledgementNumber + 1 : 1
      }`;

      return (
        <div
          className="border border-gray-2 px-2 py-1 bg-gray-light-2  mb-5"
          key={acknowledgementNumber}
        >
          <H2
            innerContent={title}
            additionalClasses="border-b border-gray-2"
            paddingClasses="pb-2 mb-2"
          />

          <InputWrapper
            title="Acknowledgement Type"
            infoBoxContent={infoBoxesContent.acknowledgementTypeInfo}
          >
            <SelectField
              name="type"
              options={[
                {
                  label: "Acknowledgements to Earlier Versions",
                  value: "earlierVersion",
                },
                {
                  label: "Acknowledgements of Other Sources",
                  value: "otherSource",
                },
              ]}
              acknowledgementNumber={acknowledgementNumber}
              triggerSaveMessageHandler={triggerSaveMessageHandler}
              isMulti={false}
            />
            {/* ^^ TO DO: not working yet */}
          </InputWrapper>

          <InputWrapper
            title="Publication Type"
            customClasses={
              isEmpty(
                submissionState.acknowledgement[acknowledgementNumber].type
              )
                ? "hidden"
                : ""
            }
          >
            <SelectField
              name="publicationType"
              options={[
                {
                  label: "Journal Article",
                  value: "Journal Article",
                },
                {
                  label: "Book",
                  value: "Book",
                },
                {
                  label: "Chapter",
                  value: "Chapter",
                },
                {
                  label: "Proceedings",
                  value: "Proceedings",
                },
                {
                  label: "Thesis",
                  value: "Thesis",
                },
                {
                  label: "Other",
                  value: "Other",
                },
              ]}
              acknowledgementNumber={acknowledgementNumber}
              triggerSaveMessageHandler={triggerSaveMessageHandler}
              isMulti={false}
            />
          </InputWrapper>

          <PublicationTypeForm
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            acknowledgementNumber={acknowledgementNumber}
          />
        </div>
      );
    }
  );
};

interface StepProps {
  triggerSaveMessageHandler: any;
}

export default function Step6(props: StepProps) {
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const { triggerSaveMessageHandler } = props;

  const query = useStaticQuery(graphql`
    query {
      submissionTextQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Step 6 text" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionText = query.submissionTextQuery.edges[0].node.html;

  return (
    <>
      <div className="pb-5 mb-5 border-b border-b-gray-2">
        <div
          className="markdownText p0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: submissionText,
          }}
        />
      </div>

      <TypeDropdown triggerSaveMessageHandler={triggerSaveMessageHandler} />

      <div className="mb-5">
        <Button
          color="blue"
          onClick={() =>
            submissionDispatch({ type: "addAnotherAcknowledgementHandler" })
          }
        >
          Add (another) acknowledgement
        </Button>
      </div>
    </>
  );
}

TypeDropdown.defaultProps = {
  triggerSaveMessageHandler: () => {},
};

TypeDropdown.defaultProps = {
  triggerSaveMessageHandler: () => {},
};
