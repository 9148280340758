/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext, useState } from "react";
import clsx from "clsx";
import { isEmpty } from "lodash";
import getApiData from "../../../lib/getApiData";
import InputField from "./inputField";
import Button from "../../htmlElements/button";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { AuthenticationState } from "../../../context/AuthenticationContext";
import { getProjectName } from "../../../lib/functions";

interface GetCurrentValuesProps {
  name: string;
  referenceNumber?: any;
  acknowledgementNumber?: any;
  submissionState: any;
}

const getCurrentValues = (props: GetCurrentValuesProps) => {
  const { name, referenceNumber, acknowledgementNumber, submissionState } =
    props;

  const projectName = getProjectName(true);

  const fieldValues = InputFieldsHelper.getFieldValues({
    referenceNumber,
    acknowledgementNumber,
    name,
    submissionState,
    projectName,
  });

  return fieldValues;
};

interface NewAuthorFormProps {
  authors: any;
  setAuthors: any;
  fieldName: string;
  categoryName: string;
  triggerSaveMessageHandler: any;
  referenceNumber?: any;
  acknowledgementNumber?: any;
  authorOrEditor?: string;
  materialSettings?: any;
  doiField?: boolean;
}

export default function NewAuthorForm(props: NewAuthorFormProps) {
  const submissionState = useContext(SubmissionState) || {
    newAuthorFirstname: "",
    newAuthorLastname: "",
  };
  const submissionDispatch = useContext(SubmissionDispatch) || "";
  const authenticationState = useContext(AuthenticationState) || "";

  const projectName = getProjectName(true);

  const {
    authors,
    setAuthors,
    fieldName: name,
    categoryName,
    triggerSaveMessageHandler,
    referenceNumber,
    acknowledgementNumber,
    authorOrEditor,
    materialSettings,
    doiField,
  } = props;

  const { newAuthorFirstname, newAuthorLastname } = submissionState;

  const defaultButtonText = `Add new ${authorOrEditor}`;
  const loadingButtonText = "Please wait, adding author";

  const onChangeHandler = InputFieldsHelper.getOnChangeHandler({
    referenceNumber,
    acknowledgementNumber,
    name,
    projectName,
  });

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  const disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: true,
  });

  const [newAuthorFirstnameClasses, setAuthorFirstnameClasses] = useState("");
  const [newAuthorLastnameClasses, setAuthorLastnameClasses] = useState("");
  const [newAuthorButtonClasses, setAuthorButtonClasses] = useState("");
  const [newAuthorButtonText, setAuthorButtonText] = useState(defaultButtonText); // eslint-disable-line prettier/prettier
  const [newAuthorButtonDisabledState, setAuthorButtonDisabledState] = useState(false); // eslint-disable-line prettier/prettier

  const addNewAuthorHandler = async () => {
    // Reset missing field classes
    setAuthorFirstnameClasses("");
    setAuthorLastnameClasses("");

    setAuthorButtonDisabledState(true);
    setAuthorButtonText(loadingButtonText);

    const currentValues = getCurrentValues({
      name,
      referenceNumber,
      acknowledgementNumber,
      submissionState,
    });

    if (isEmpty(newAuthorFirstname) && isEmpty(newAuthorLastname)) {
      // Missing fistname and lastname

      setAuthorFirstnameClasses("missingName");
      setAuthorLastnameClasses("missingName");
    } else if (!isEmpty(newAuthorFirstname) && isEmpty(newAuthorLastname)) {
      // Missing lastname

      setAuthorLastnameClasses("missingName");
    } else if (!isEmpty(newAuthorLastname) && isEmpty(newAuthorFirstname)) {
      // Missing firstname

      setAuthorFirstnameClasses("missingName");
    } else {
      const newAuthorName = `${newAuthorLastname}, ${newAuthorFirstname}`;

      /**
       * Check if the new author is already in the author list.
       */
      const newAuthorCheck = authors.filter(
        (item) =>
          item &&
          item.label &&
          item?.label?.toLowerCase() === newAuthorName?.toLowerCase()
      );

      //
      //
      //
      //
      // What to do? Message? Or just add the author.
      //
      //
      //
      //

      /**
       * If the new author does not exist, add it
       */
      if (newAuthorCheck.length < 1) {
        // Add new author to DD - through API endpoint
        const newAuthor = await getApiData({
          endpoint: "dd/author",
          method: "post",
          params: {
            author: newAuthorName,
            category: categoryName, // either IRIS or OASIS category
            projectName,
          },
          headers: {
            "X-Amz-Security-Token": authenticationState.token,
          },
        });

        const newAuthorMetadata = {
          label: newAuthor.data.metadata.label,
          value: newAuthor.data.metadata.id,
          depth: 0,
          id: newAuthor.data.metadata.id,
          subItems: [],
          formerId: newAuthor.data.metadata.formerId,
          order: 0,
        };

        // Create the new author option
        // const newOption: any = {
        //   id: newAuthor?.data?.newItemID || newAuthorName,
        //   label: newAuthorName,
        //   value: newAuthorName,
        // };

        // console.log("🚀 ~ addNewAuthorHandler ~ newOption:", newOption);

        // Push the new author to the dropdown menu list.
        authors.push(newAuthorMetadata);
        setAuthors(authors);

        // Combine the existing selected options (currentValues) and add the new option.
        const selectedOptions = currentValues
          .filter((item: any) => !isEmpty(item))
          .concat(newAuthorMetadata);

        // Push the new author to the state so it's selected in the dropdown menu
        submissionDispatch({
          type: onChangeHandler,
          value: selectedOptions,
          fieldName: name,
          referenceNumber,
          acknowledgementNumber,
        });

        // Clear the form for adding a new author
        submissionDispatch({
          type: "inputChangeHandler",
          value: "",
          fieldName: "newAuthorFirstname",
        });

        // Clear the form for adding a new author
        submissionDispatch({
          type: "inputChangeHandler",
          value: "",
          fieldName: "newAuthorLastname",
        });
      } else {
        // console.log("🚀 ~ else ~ newAuthorCheck:", newAuthorCheck);
        // console.log("🚀 ~ else ~ currentValues:", currentValues);

        // Combine the existing selected options (currentValues) and add the new option.
        const selectedOptions = currentValues.concat(newAuthorCheck[0]);

        // Push the new author to the state so it's selected in the dropdown menu
        submissionDispatch({
          type: onChangeHandler,
          value: selectedOptions,
          fieldName: name,
          referenceNumber,
          acknowledgementNumber,
        });

        // Clear the form for adding a new author
        submissionDispatch({
          type: "inputChangeHandler",
          value: "",
          fieldName: "newAuthorFirstname",
        });

        // Clear the form for adding a new author
        submissionDispatch({
          type: "inputChangeHandler",
          value: "",
          fieldName: "newAuthorLastname",
        });
      }

      setTimeout(() => {
        triggerSaveMessageHandler();
      }, 500);
    }

    // Reset the form again
    setAuthorButtonDisabledState(false);
    setAuthorButtonText(defaultButtonText);
  };

  return disabled ? (
    ""
  ) : (
    <>
      <div className="p-2">Or add a new {authorOrEditor}:</div>
      <div className="grid grid-cols-8 gap-2">
        <div className="col-span-3">
          <InputField
            name="newAuthorFirstname"
            placeholder="Firstname"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            additionalClasses={clsx("inline w-full", newAuthorFirstnameClasses)}
            disabled={newAuthorButtonDisabledState}
          />
        </div>
        <div className="col-span-3">
          <InputField
            name="newAuthorLastname"
            placeholder="Lastname"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            additionalClasses={clsx("inline w-full", newAuthorLastnameClasses)}
            disabled={newAuthorButtonDisabledState}
          />
        </div>
        <div className="col-span-2 text-right">
          <Button
            color={`${projectName.toLowerCase()}-blue`}
            textSize="xs"
            onClick={addNewAuthorHandler}
            additionalClasses={clsx(
              "newAuthorButton w-full",
              newAuthorButtonClasses
            )}
            disabled={newAuthorButtonDisabledState}
          >
            {newAuthorButtonText}
          </Button>
        </div>
      </div>
    </>
  );
}

NewAuthorForm.defaultProps = {
  authorOrEditor: "author",
};
