/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { isEmpty } from "lodash";
import clsx from "clsx";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { getProjectName } from "../../../lib/functions";

let saveTimer: null | ReturnType<typeof setTimeout> = null;

interface InputFieldProps {
  name: string;
  options: any;
  placeholder?: string;
  isMulti?: boolean;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  triggerSaveMessageHandler?: any;
  zIndex?: number;
  materialSettings?: any;
  doiField?: boolean;
  customChangeHandler?: any;
  customFieldValues?: any;
  additionalClasses?: any;
}

export default function SelectField(props: InputFieldProps) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const projectName = getProjectName(true);

  const {
    name,
    options,
    placeholder,
    isMulti,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
    zIndex,
    materialSettings,
    doiField,
    customChangeHandler,
    customFieldValues,
    additionalClasses,
  } = props;

  let fieldValues: any = "";

  fieldValues =
    customFieldValues ||
    InputFieldsHelper.getFieldValues({
      referenceNumber,
      acknowledgementNumber,
      name,
      submissionState,
      projectName,
    });

  // This will make sure that the `fieldValues` is always the full DD information, not just the `value`
  if (fieldValues.length > 0) {
    fieldValues = options.filter((item: any) => {
      return fieldValues.map((item: any) => item.value).includes(item.value);
    });
  }

  const onChangeHandler =
    customChangeHandler ||
    InputFieldsHelper.getOnChangeHandler({
      referenceNumber,
      acknowledgementNumber,
      name,
      projectName,
    });

  // Update field values when `customFieldValues` has been updated
  // This overwrites the previous value
  // useEffect(() => {
  //   fieldValues = customFieldValues
  // }, [customFieldValues]);

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  const disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: doiField || false,
  });

  const noteText = InputFieldsHelper.getInputDisabledNote({
    materialSettings,
    doiField: doiField || false,
  });

  // Add a z-index is a value is passed
  const customStyles = zIndex
    ? {
        container: (provided: any) => ({ ...provided, zIndex }),
      }
    : {
        container: (provided: any) => ({ ...provided }),
      };

  /**
   * Filter the `options` to remove `REJECTED` options unless it's specific for this material.
   */
  const filteredOptions = options.filter(
    (item: any) =>
      item.status !== "REJECTED" || item.materialId === submissionState.objectId
  );

  return (
    <div key={name} className={clsx("mt-2", additionalClasses)}>
      {noteText}
      <Select
        isDisabled={disabled}
        isMulti={isMulti !== false}
        isSearchable
        name={name}
        placeholder={placeholder || "Please select..."}
        options={filteredOptions}
        // styles={clsx(customStyles, additionalClasses)}
        styles={customStyles}
        onChange={(selectedOption) => {
          if (customChangeHandler) {
            customChangeHandler(selectedOption);
          } else {
            // Cancel current saveTimer when new changes are coming in before timeout passes
            clearTimeout(saveTimer);

            // console.log("selectedOption: ", selectedOption);

            // console.log("submissionDispatch: ", {
            //   type: onChangeHandler,
            //   value: selectedOption,
            //   fieldName: name,
            //   referenceNumber,
            //   acknowledgementNumber,
            // });

            submissionDispatch({
              type: onChangeHandler,
              value: selectedOption,
              fieldName: name,
              referenceNumber,
              acknowledgementNumber,
            });

            // Trigger save after X milliseconds to avoid too many save requests
            // timer is canceled on new change event
            saveTimer = setTimeout(() => {
              triggerSaveMessageHandler();
            }, 500);
          }
        }}
        value={fieldValues.filter((item: any) => !isEmpty(item))}
      />
    </div>
  );
}
