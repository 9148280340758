import React from "react";
import { getProjectName } from "../../lib/functions";
import submissionSteps from "../../settings/submissionSteps";

interface StepDisplayProps {
  step: number;
  submissionStatus: any;
  missingRequiredFields?: any;
  triggerSaveMessageHandler: any;
  materialID: string;
  materialSettings: any;
}

export default function StepDisplay(props: StepDisplayProps) {
  const {
    step,
    triggerSaveMessageHandler,
    submissionStatus,
    missingRequiredFields,
    materialID,
    materialSettings,
  } = props;

  const projectName = getProjectName();

  // @ts-ignore
  const StepComponent: any = Object.values(submissionSteps[projectName])[step].stepComponent; // eslint-disable-line prettier/prettier

  return (
    <StepComponent
      triggerSaveMessageHandler={triggerSaveMessageHandler}
      materialSettings={materialSettings}
      submissionStatus={submissionStatus}
      missingRequiredFields={missingRequiredFields}
      materialID={materialID}
    />
  );
}

StepDisplay.defaultProps = {
  missingRequiredFields: [],
};
