import React, { useContext, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { SubmissionDispatch } from "../../../../context/SubmissionContext";

export default function OasisConfirmationStep(props: any) {
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const { materialID } = props;

  useEffect(() => {
    /**
     * Empty context here!!!
     */

    setTimeout(() => {
      submissionDispatch({
        type: "clearSubmissionState",
      });
    }, 500);
  }, []);

  const { introText } = useStaticQuery(graphql`
    query {
      introText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-submission-step-8" } }
      ) {
        html
      }
    }
  `);

  let text = "";

  text = introText.html.replace(
    "here",
    `<a href="/details/${materialID}">here</a>`
  );

  return introText.html ? (
    <div
      className="markdownText p0"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  ) : null;
}
