/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";

import InputWrapper from "../../inputWrapper";
import { DictionaryDataState } from "../../../../context/DictionaryDataContext";
import LoadingIcon from "../../../loadingIcon";
import SelectField from "../../fields/selectField";
import InputField from "../../fields/inputField";
import NewAuthorForm from "../../fields/newAuthorForm";
import NewOtherForm from "../../fields/newOtherForm";

interface StepProps {
  triggerSaveMessageHandler: any;
  materialSettings: any;
  submissionStatus?: any;
  missingRequiredFields?: any;
  materialID?: string;
}

export default function OasisSummaryDetailsStep(props: StepProps) {
  const dictionaryDataState: any = useContext(DictionaryDataState) || [];

  const { triggerSaveMessageHandler, materialSettings } = props;

  // States
  const [loaded, setLoaded] = useState(true);
  const [summaryWriter, setSummaryWriter] = useState<object>([]); // eslint-disable-line prettier/prettier
  const [summaryLanguageSummaryWrittenIn, setSummaryLanguageSummaryWrittenIn] = useState<object>([]); // eslint-disable-line prettier/prettier
  const [summaryCollectionNames, setSummaryCollectionNames] = useState<any>([]); // eslint-disable-line prettier/prettier

  const setDictionaryData = (updateLoaded = false) => {
    if (!isEmpty(dictionaryDataState)) {
      /* eslint-disable */

      setSummaryWriter(dictionaryDataState.oasisAuthors);
      setSummaryLanguageSummaryWrittenIn(dictionaryDataState.oasisSummaryLanguageWrittenIn);
      setSummaryCollectionNames(dictionaryDataState.oasisSummaryCollectionName);

      /* eslint-enable */

      // Set loaded to `true` so that the content is shown to the user
      if (updateLoaded) {
        setLoaded(true);
      }
    }
  };

  /**
   * We need to set the new values from the dictionaryDataState once they are updated - they
   * can be updated from for example `newOtherForm`
   */
  useEffect(() => {
    setDictionaryData(true);
  }, [dictionaryDataState]);

  const { introText, infoBoxesContent, subTitlesContent } =
    useStaticQuery(graphql`
      query {
        introText: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-submission-step-1" } }
        ) {
          html
        }

        infoBoxesContent: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-info-boxes" } }
        ) {
          frontmatter {
            writerOfSummary
            titleOfSummary
            languageSummaryWrittenIn
            summaryOtherVersions
          }
        }

        subTitlesContent: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-subtitles-content" } }
        ) {
          frontmatter {
            writerOfSummary
            titleOfSummary
            languageSummaryWrittenIn
            summaryOtherVersions
          }
        }
      }
    `);

  return loaded ? (
    <>
      {introText.html ? (
        <div className="pb-5 mb-5 border-b border-b-gray-2">
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: introText.html,
            }}
          />
        </div>
      ) : null}

      <InputWrapper
        title="Writer(s) of summary"
        infoBoxContent={infoBoxesContent.frontmatter.writerOfSummary}
        required
      >
        {subTitlesContent.frontmatter.writerOfSummary ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.writerOfSummary,
            }}
          />
        ) : null}

        <SelectField
          name="summaryWriter"
          options={summaryWriter}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          required
          zIndex={6000}
          materialSettings={materialSettings}
          doiField
        />
        <NewAuthorForm
          authors={summaryWriter}
          setAuthors={setSummaryWriter}
          fieldName="summaryWriter"
          categoryName="oasisAuthors"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          doiField
        />
      </InputWrapper>

      <InputWrapper
        title="Title of summary"
        infoBoxContent={infoBoxesContent.frontmatter.titleOfSummary}
        required
      >
        {subTitlesContent.frontmatter.titleOfSummary ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.titleOfSummary,
            }}
          />
        ) : null}

        <InputField
          name="summaryTitle"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          // materialSettings={materialSettings}
          // doiField
        />
      </InputWrapper>

      <InputWrapper
        title="Language summary written in"
        infoBoxContent={infoBoxesContent.frontmatter.languageSummaryWrittenIn}
        required
      >
        {subTitlesContent.frontmatter.languageSummaryWrittenIn ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.languageSummaryWrittenIn,
            }}
          />
        ) : null}

        <SelectField
          name="summaryLanguageSummaryWrittenIn"
          options={summaryLanguageSummaryWrittenIn}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={5000}
        />
      </InputWrapper>

      <InputWrapper
        title="Summaries available in other languages"
        infoBoxContent={infoBoxesContent.frontmatter.summaryOtherVersions}
      >
        {subTitlesContent.frontmatter.summaryOtherVersions ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.summaryOtherVersions,
            }}
          />
        ) : null}

        <InputField
          name="summaryOtherVersions"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          isMulti
        />
      </InputWrapper>

      <InputWrapper
        title="Collection name"
        infoBoxContent={infoBoxesContent.frontmatter.summaryCollectionName}
      >
        {subTitlesContent.frontmatter.summaryCollectionName ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.summaryCollectionName,
            }}
          />
        ) : null}

        <SelectField
          name="summaryCollectionName"
          options={summaryCollectionNames.map((item: any) => {
            return { value: item.value, label: item.label };
          })}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="collection name"
            inputName="summaryCollectionNameNew"
            fieldName="summaryCollectionName"
            dictionaryStateName="summaryCollectionName"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            referenceNumber=""
            options={summaryCollectionNames.map((item: any) => {
              return { value: item.value, label: item.label };
            })}
          />
        </span>
      </InputWrapper>
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}
