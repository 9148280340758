import OasisMapping from "../../oasisMapping.json";

const irisInstrumentPropertyList = [
  "creator",
  "materialType",
  "researchArea",
  "typeOfFile",
  "dataType",
  "linguisticFeature",
  "language",
  "sourceOfFunding",
  "title",
  "reliability",
  "notes",
];

const irisParticipantsPropertyList = [
  "type",
  "firstLanguage",
  "languageBeingLearned",
  "ageOfLearner",
  "genderOfLearners",
  "proficiencyOfLearners",
  "typeOfInstruction",
  "amountOfInstruction",
  "lengthOfResidence",
  "ageOfLearner",
  "amountOfPriorInstruction",
  "timeSpent",
  "domainOfUse",
  "ageOfArrival",
];

const irisSettingsPropertyList = [
  "rights",
  "feedback",
  "notifyDownloads",
  "email",
  "comments",
  "licenceAgreement",
];

const oasisSummaryPropertyList = OasisMapping.filter(
  (item: any) => item.partOf === "summary"
).map((item: any) => item.fieldName);

const oasisParticipantsPropertyList = OasisMapping.filter(
  (item: any) => item.partOf === "participant"
).map((item: any) => item.fieldName);

const oasisPublicationPropertyList = OasisMapping.filter(
  (item: any) => item.partOf === "publication"
).map((item: any) => item.fieldName);

const oasisSettingsPropertyList = OasisMapping.filter(
  (item: any) => item.partOf === "settings"
).map((item: any) => item.fieldName);

const oasisResearchPropertyList = [
  "researchTitle",
  "researchText",
  "whatResearchersDidText",
  "whatResearchersFoundText",
  "thingsToConsiderText",
];

export {
  irisInstrumentPropertyList,
  irisParticipantsPropertyList,
  irisSettingsPropertyList,
  oasisSummaryPropertyList,
  oasisParticipantsPropertyList,
  oasisPublicationPropertyList,
  oasisSettingsPropertyList,
  oasisResearchPropertyList,
};
