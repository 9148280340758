import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { isEmpty } from "lodash";
import { DictionaryDataState } from "../../../../context/DictionaryDataContext";
import InputWrapper from "../../inputWrapper";
import InputField from "../../fields/inputField";
import LoadingIcon from "../../../loadingIcon";
import SelectField from "../../fields/selectField";
import TextareaField from "../../fields/textareaField";
import NewOtherForm from "../../fields/newOtherForm";

interface StepProps {
  triggerSaveMessageHandler: any;
  materialSettings: any;
}

export default function OasisParticipantsStep(props: StepProps) {
  const dictionaryDataState: any = useContext(DictionaryDataState) || [];

  const { triggerSaveMessageHandler, materialSettings } = props;

  // States
  /* eslint-disable */
  const [loaded, setLoaded] = useState(true);
  const [oasisParticipantLinguisticTarget, setOasisParticipantLinguisticTarget] = useState([]);
  const [participantFirstLanguageOfLearners, setParticipantFirstLanguageOfLearners] = useState([]);
  const [participantTargetLanguage, setParticipantTargetLanguage] = useState([]);
  const [oasisParticipantAgeOfLearners, setOasisParticipantAgeOfLearners] = useState([]);
  const [oasisParticipantGender, setOasisParticipantGender] = useState([]);
  const [oasisParticipantProficiencies, setOasisParticipantProficiencies] = useState([]);
  const [oasisParticipantDomainOfUse, setOasisParticipantDomainOfUse] = useState([]);
  const [oasisParticipantCountry, setOasisParticipantCountry] = useState([]);
  const [oasisParticipantEducationalStage, setOasisParticipantEducationalStage] = useState([]);
  const [oasisParticipantInstitutionalCharacteristics, setOasisParticipantInstitutionalCharacteristics] = useState([]);
  /* eslint-enable */

  const setDictionaryData = (updateLoaded = false) => {
    if (!isEmpty(dictionaryDataState)) {
      /* eslint-disable */
      setOasisParticipantLinguisticTarget(dictionaryDataState.oasisParticipantLinguisticTarget);
      setParticipantFirstLanguageOfLearners(dictionaryDataState.oasisParticipantFirstLanguage);
      setParticipantTargetLanguage(dictionaryDataState.oasisParticipantLanguagesBeingLearned);
      setOasisParticipantAgeOfLearners(dictionaryDataState.oasisParticipantAgeOfLearners);
      setOasisParticipantGender(dictionaryDataState.oasisParticipantGender);
      setOasisParticipantProficiencies(dictionaryDataState.oasisParticipantProficiencies);
      setOasisParticipantDomainOfUse(dictionaryDataState.oasisParticipantDomainOfUse);
      setOasisParticipantCountry(dictionaryDataState.oasisParticipantCountry);
      setOasisParticipantEducationalStage(dictionaryDataState.oasisParticipantEducationalStage);
      setOasisParticipantInstitutionalCharacteristics(dictionaryDataState.oasisParticipantInstitutionalCharacteristics);
      /* eslint-enable */

      // Set loaded to `true` so that the content is shown to the user
      if (updateLoaded) {
        setLoaded(true);
      }
    }
  };

  /**
   * We need to set the new values from the dictionaryDataState once they are updated - they
   * can be updated from for example `newOtherForm`
   */
  useEffect(() => {
    setDictionaryData(true);
  }, [dictionaryDataState]);

  const { introText, infoBoxesContent, subTitlesContent } =
    useStaticQuery(graphql`
      query {
        introText: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-submission-step-2" } }
        ) {
          html
        }

        infoBoxesContent: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-info-boxes" } }
        ) {
          frontmatter {
            featureBeingLearned
            firstLanguageOfLearners
            languageBeingLearned
            ageOfLearners
            genderOfLearners
            proficiencyOfLearners
            typeOfInstruction
            amountOfInstruction
            lengthOfResidenceOfLearners
            ageOfLearnersAtArrival
            amountOfPriorInstruction
            timeSpentUsingTheL2
            contextOfLanguageUse
            yearsOfTeachingExperience
            countryRegion
            subnationalRegion
            educationalStage
            institutionalCharacteristics
          }
        }

        subTitlesContent: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-subtitles-content" } }
        ) {
          frontmatter {
            featureBeingLearned
            firstLanguageOfLearners
            languageBeingLearned
            ageOfLearners
            genderOfLearners
            proficiencyOfLearners
            typeOfInstruction
            amountOfInstruction
            lengthOfResidenceOfLearners
            ageOfLearnersAtArrival
            amountOfPriorInstruction
            timeSpentUsingTheL2
            contextOfLanguageUse
            yearsOfTeachingExperience
            countryRegion
            subnationalRegion
            educationalStage
            institutionalCharacteristics
          }
        }
      }
    `);

  return loaded ? (
    <>
      {introText.html ? (
        <div className="pb-5 mb-5 border-b border-b-gray-2">
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: introText.html,
            }}
          />
        </div>
      ) : null}

      <InputWrapper
        title="Feature being learned"
        infoBoxContent={infoBoxesContent.frontmatter.featureBeingLearned}
      >
        {subTitlesContent.frontmatter.featureBeingLearned ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.featureBeingLearned,
            }}
          />
        ) : null}
        {/* Linguistic Target */}
        <SelectField
          name="participantLinguisticTarget"
          options={oasisParticipantLinguisticTarget}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={7000}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="feature"
            inputName="oasisParticipantLinguisticTargetNew"
            fieldName="oasisParticipantLinguisticTarget"
            dictionaryStateName="oasisParticipantLinguisticTarget"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={oasisParticipantLinguisticTarget}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="First language of learners"
        infoBoxContent={infoBoxesContent.frontmatter.firstLanguageOfLearners}
      >
        {subTitlesContent.frontmatter.firstLanguageOfLearners ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.firstLanguageOfLearners,
            }}
          />
        ) : null}
        <SelectField
          name="participantFirstLanguageOfLearners"
          options={participantFirstLanguageOfLearners}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6900}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="first language"
            inputName="participantFirstLanguageOfLearnersNew"
            fieldName="participantFirstLanguageOfLearners"
            dictionaryStateName="oasisParticipantFirstLanguage"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={participantFirstLanguageOfLearners}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="Language being learned"
        infoBoxContent={infoBoxesContent.frontmatter.languageBeingLearned}
      >
        {subTitlesContent.frontmatter.languageBeingLearned ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.languageBeingLearned,
            }}
          />
        ) : null}
        <SelectField
          name="participantTargetLanguage"
          options={participantTargetLanguage}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6800}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="language"
            inputName="participantTargetLanguageNew"
            fieldName="participantTargetLanguage"
            dictionaryStateName="oasisParticipantLanguagesBeingLearned"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={participantTargetLanguage}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="Age of learners"
        infoBoxContent={infoBoxesContent.frontmatter.ageOfLearners}
      >
        {subTitlesContent.frontmatter.languageBeingLearned ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.languageBeingLearned,
            }}
          />
        ) : null}
        <SelectField
          name="participantAgeOfLearners"
          options={oasisParticipantAgeOfLearners}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6700}
        />
      </InputWrapper>

      <InputWrapper
        title="Gender of learners"
        infoBoxContent={infoBoxesContent.frontmatter.genderOfLearners}
      >
        {subTitlesContent.frontmatter.genderOfLearners ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.genderOfLearners,
            }}
          />
        ) : null}
        <SelectField
          name="participantGender"
          options={oasisParticipantGender}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6600}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="gender"
            inputName="participantGenderNew"
            fieldName="participantGender"
            dictionaryStateName="oasisParticipantProficiencies"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={oasisParticipantGender}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="Proficiency of learners"
        infoBoxContent={infoBoxesContent.frontmatter.proficiencyOfLearners}
      >
        {subTitlesContent.frontmatter.proficiencyOfLearners ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.proficiencyOfLearners,
            }}
          />
        ) : null}
        <SelectField
          name="participantProficiencyOfLearners"
          options={oasisParticipantProficiencies}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6500}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="proficiency"
            inputName="participantProficiencyOfLearnersNew"
            fieldName="participantProficiencyOfLearners"
            dictionaryStateName="oasisParticipantProficiencies"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={oasisParticipantProficiencies}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="Type of instruction"
        infoBoxContent={infoBoxesContent.frontmatter.typeOfInstruction}
      >
        {subTitlesContent.frontmatter.typeOfInstruction ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.typeOfInstruction,
            }}
          />
        ) : null}
        <InputField
          name="participantTypeOfInstruction"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Amount of instruction"
        infoBoxContent={infoBoxesContent.frontmatter.amountOfInstruction}
      >
        {subTitlesContent.frontmatter.amountOfInstruction ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.amountOfInstruction,
            }}
          />
        ) : null}
        <InputField
          name="participantAmountOfInstruction"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Length of residence of learners"
        infoBoxContent={
          infoBoxesContent.frontmatter.lengthOfResidenceOfLearners
        }
      >
        {subTitlesContent.frontmatter.lengthOfResidenceOfLearners ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.lengthOfResidenceOfLearners,
            }}
          />
        ) : null}
        <InputField
          name="participantLengthOfResidence"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Age of learners at arrival"
        infoBoxContent={infoBoxesContent.frontmatter.ageOfLearnersAtArrival}
      >
        {subTitlesContent.frontmatter.ageOfLearnersAtArrival ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.ageOfLearnersAtArrival,
            }}
          />
        ) : null}
        <InputField
          name="participantAgeOfLearnersOfArrival"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Amount of prior instruction"
        infoBoxContent={infoBoxesContent.frontmatter.amountOfPriorInstruction}
      >
        {subTitlesContent.frontmatter.amountOfPriorInstruction ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.amountOfPriorInstruction,
            }}
          />
        ) : null}
        <InputField
          name="participantAmountOfPriorInstruction"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Time spent using the L2"
        infoBoxContent={infoBoxesContent.frontmatter.timeSpentUsingTheL2}
      >
        {subTitlesContent.frontmatter.timeSpentUsingTheL2 ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.timeSpentUsingTheL2,
            }}
          />
        ) : null}
        <InputField
          name="participantTimeSpentUsingL2"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Context of language use"
        infoBoxContent={infoBoxesContent.frontmatter.contextOfLanguageUse}
      >
        {subTitlesContent.frontmatter.contextOfLanguageUse ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.contextOfLanguageUse,
            }}
          />
        ) : null}
        <SelectField
          name="participantDomainOfUse"
          options={oasisParticipantDomainOfUse}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6400}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="context"
            inputName="participantDomainOfUseNew"
            fieldName="participantDomainOfUse"
            dictionaryStateName="oasisParticipantDomainOfUse"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={oasisParticipantDomainOfUse}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="Years of teaching experience"
        infoBoxContent={infoBoxesContent.frontmatter.yearsOfTeachingExperience}
      >
        {subTitlesContent.frontmatter.yearsOfTeachingExperience ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.yearsOfTeachingExperience,
            }}
          />
        ) : null}
        <InputField
          name="participantYearOfTeachingExperience"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Country / region"
        infoBoxContent={infoBoxesContent.frontmatter.countryRegion}
      >
        {subTitlesContent.frontmatter.countryRegion ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.countryRegion,
            }}
          />
        ) : null}
        <SelectField
          name="participantCountry"
          options={oasisParticipantCountry}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6300}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="country / region"
            inputName="participantCountryNew"
            fieldName="participantCountry"
            dictionaryStateName="oasisParticipantCountry"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={oasisParticipantCountry}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="Subnational region"
        infoBoxContent={infoBoxesContent.frontmatter.subnationalRegion}
      >
        {subTitlesContent.frontmatter.subnationalRegion ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.subnationalRegion,
            }}
          />
        ) : null}
        <InputField
          name="participantSubnationalRegion"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Educational stage"
        infoBoxContent={infoBoxesContent.frontmatter.educationalStage}
      >
        {subTitlesContent.frontmatter.educationalStage ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.educationalStage,
            }}
          />
        ) : null}
        <SelectField
          name="participantEducationalStage"
          options={oasisParticipantEducationalStage}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6200}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="educational stage"
            inputName="participantEducationalStageNew"
            fieldName="participantEducationalStage"
            dictionaryStateName="oasisParticipantEducationalStage"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={oasisParticipantEducationalStage}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="Institutional characteristics"
        infoBoxContent={
          infoBoxesContent.frontmatter.institutionalCharacteristics
        }
      >
        {subTitlesContent.frontmatter.institutionalCharacteristics ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.institutionalCharacteristics,
            }}
          />
        ) : null}
        <SelectField
          name="participantInstitutionalCharacteristics"
          options={oasisParticipantInstitutionalCharacteristics}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          zIndex={6100}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="institutional characteristic"
            inputName="participantInstitutionalCharacteristicsNew"
            fieldName="participantInstitutionalCharacteristics"
            dictionaryStateName="oasisParticipantInstitutionalCharacteristics"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            options={oasisParticipantInstitutionalCharacteristics}
          />
        </span>
      </InputWrapper>

      <InputWrapper
        title="Notes"
        infoBoxContent={infoBoxesContent.materialNotesInfo}
      >
        {subTitlesContent.frontmatter.materialNotesInfo ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.materialNotesInfo,
            }}
          />
        ) : null}
        <TextareaField
          name="settingsNotes"
          height={100}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}
