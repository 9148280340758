/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { isArray, isEmpty } from "lodash";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { getProjectName } from "../../../lib/functions";

let saveTimer: null | ReturnType<typeof setTimeout> = null;

interface InputFieldProps {
  name: string;
  placeholder?: string | string[];
  preText?: string[];
  additionalClasses?: string;
  isMulti?: boolean;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  triggerSaveMessageHandler?: any;
  disabled?: boolean;
  materialSettings?: any;
  doiField?: boolean;
}

export default function InputField(props: InputFieldProps) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const projectName = getProjectName(true);

  let { disabled } = props;

  const {
    name,
    placeholder,
    preText,
    additionalClasses,
    isMulti,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
    materialSettings,
    doiField,
  } = props;

  const [fieldValues, setFieldValues] = useState<any>([]);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  // const fieldValues = InputFieldsHelper.getFieldValues({
  //   referenceNumber,
  //   acknowledgementNumber,
  //   name,
  //   submissionState,
  //   projectName,
  // });

  const onChangeHandler = InputFieldsHelper.getOnChangeHandler({
    referenceNumber,
    acknowledgementNumber,
    name,
    projectName,
  });

  /**
   * Check the `status` of the material - if `published` we disable the input field
   *
   * Exeption: We do not disable the field if the user is an admin user
   */
  disabled = InputFieldsHelper.checkInputDisabledStatus({
    materialSettings,
    doiField: doiField || false,
  });

  const noteText = InputFieldsHelper.getInputDisabledNote({
    materialSettings,
    doiField: doiField || false,
  });

  const addEmptyInputValue = () => {
    // console.log("🚀 ~ addEmptyInputValue ~ fieldValues:", fieldValues);

    // Ensure all current field values are not empty before adding a new one
    const allFieldsFilled = fieldValues.every((item: any) => !isEmpty(item));

    // console.log("🚀 ~ addEmptyInputValue ~ allFieldsFilled:", allFieldsFilled);

    if (allFieldsFilled) {
      submissionDispatch({
        type: onChangeHandler,
        value: "",
        fieldName: isMulti ? `${name}[]` : name,
        i: fieldValues.length,
        isMulti,
        referenceNumber,
        acknowledgementNumber,
      });

      setTimeout(() => {
        updateFieldValues();
      }, 300);
    } else {
      // Optionally, you could show a message to the user here indicating that all fields must be filled
      console.log("All fields must be filled before adding a new one.");
      setShowWarning(true);
    }
  };

  const deleteInputHandler = (i: number) => {
    // const fieldName = isMulti ? `${name}[]` : name;

    // console.log("🚀 ~ deleteInputHandler ~ i:", i);
    // console.log("🚀 ~ deleteInputHandler ~ onChangeHandler:", onChangeHandler);
    // console.log("🚀 ~ deleteInputHandler ~ fieldName:", fieldName);

    // console.log("submissionState: ", submissionState);
    // console.log("fieldValues: ", fieldValues);

    // Remove given key
    fieldValues.splice(i, 1);

    // console.log("fieldValues: ", fieldValues);

    // Update state
    submissionDispatch({
      type: "UPDATE",
      value: fieldValues,
      fieldName: isMulti ? `${name}[]` : name,
      referenceNumber,
      acknowledgementNumber,
    });

    setTimeout(() => {
      triggerSaveMessageHandler();
    }, 500);
  };

  const updateFieldValues = () => {
    const getFieldValues = InputFieldsHelper.getFieldValues({
      referenceNumber,
      acknowledgementNumber,
      name,
      submissionState,
      projectName,
    });
    // console.log("🚀 ~ updateFieldValues ~ getFieldValues:", getFieldValues);

    setFieldValues(getFieldValues);
  };

  useEffect(() => {
    // console.log("submissionState: ", submissionState);

    updateFieldValues();
  }, [submissionState]);

  return (
    <>
      {fieldValues.map((v: any, i: any) => {
        const key = isMulti ? `${name}[${i}]` : name;

        const showPreText = preText
          ? isArray(preText)
            ? preText[i]
            : preText || ""
          : "";

        const additionalMarginClass = i > 0 ? "mt-2" : "";

        return (
          <div key={key} className={clsx(additionalMarginClass, "relative")}>
            {showPreText}
            {noteText}
            <input
              type="text"
              id={key}
              key={key}
              placeholder={
                isArray(placeholder) ? placeholder[i] : placeholder || ""
              }
              disabled={!!disabled}
              className={clsx(
                "w-full css-1s2u09g-control css-13cymwt-control px-2",
                additionalClasses
              )}
              onChange={(e) => {
                // Cancel current saveTimer when new changes are coming in before timeout passes
                clearTimeout(saveTimer);

                // Update state
                submissionDispatch({
                  type: onChangeHandler,
                  value: e.target.value,
                  fieldName: isMulti ? `${name}[]` : name,
                  i,
                  isMulti,
                  referenceNumber,
                  acknowledgementNumber,
                });

                if (showWarning) setShowWarning(false);

                // Trigger save after X milliseconds to avoid too many save requests
                // timer is canceled on new change event
                saveTimer = setTimeout(() => {
                  triggerSaveMessageHandler();
                }, 500);
              }}
              value={v}
            />

            {isMulti && i !== 0 ? (
              <button
                type="submit"
                className="absolute top-0 end-0 p-2"
                onClick={() => {
                  deleteInputHandler(i);
                }}
              >
                ❌
              </button>
            ) : null}
          </div>
        );
      })}

      {showWarning ? (
        <div className="oasisWarning">
          Cannot add another field, please fill all available fields first.
        </div>
      ) : null}

      {isMulti && projectName !== "IRIS" ? (
        <button
          className="py-2 inline-block"
          onClick={addEmptyInputValue}
          type="button"
        >
          + add more
        </button>
      ) : null}
    </>
  );
}
