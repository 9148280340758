/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import { getProjectName, sendEventToGA } from "../../lib/functions";

import H1 from "../htmlElements/h1";
import Button from "../htmlElements/button";
import { submitMaterialMetadata } from "./submitMaterialMetadata";

import submissionSteps from "../../settings/submissionSteps";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../context/SubmissionContext";
import { DictionaryDataState } from "../../context/DictionaryDataContext";
import { AuthenticationState } from "../../context/AuthenticationContext";
import validateSubmissionForm from "../../helpers/validateSubmittionForm";

const DEFAULT_STEP_TITLE = "";
const STEP_PREFIX = "Step ";

interface RightColumnProps {
  step: number;
  materialID: string;
  children?: any;
  triggerSaveMessage?: boolean;
  setSubmissionStatus?: any;
  missingRequiredFields?: any;
  isSubmissionStep?: boolean;
  isConfirmationStep?: boolean;
}

function RightColumnContent(props: RightColumnProps) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const dictionaryDataState = useContext(DictionaryDataState) || "";
  const authenticationState = useContext(AuthenticationState) || "";

  const {
    step,
    materialID,
    children,
    triggerSaveMessage,
    missingRequiredFields,
    isSubmissionStep,
    isConfirmationStep,
    setSubmissionStatus,
  } = props;

  const { token } = authenticationState;

  const projectName = getProjectName();

  const stepSetting = Object.values(submissionSteps[projectName.toLowerCase()])
    .filter((item: any) => item.step === step)
    .reduce((item: any) => item);

  let h1 = step === 0 ? DEFAULT_STEP_TITLE : `${STEP_PREFIX}${step}: `;

  /**
   * Try to enhance the H1 title - might this fail we still have `Step X` as a title
   */
  try {
    h1 += stepSetting?.label ?? "";
  } catch (error) {
    console.log(error);
  }

  const previousStepDefaultValue = "Previous step";
  const nextStepDefaultValue = "Next step";

  const [nextStepValue, setNextStepValue] = useState(nextStepDefaultValue); // eslint-disable-line prettier/prettier
  const [nextStepProcessingState, setNextStepProcessingState] = useState(false); // eslint-disable-line prettier/prettier
  const [nextStepProcessingDoneState, setNextStepProcessingDoneState] = useState(false); // eslint-disable-line prettier/prettier
  const [previousStepValue, setPreviousStepValue] = useState(previousStepDefaultValue); // eslint-disable-line prettier/prettier
  const [previousStepProcessingState, setPreviousStepProcessingState] = useState(false); // eslint-disable-line prettier/prettier
  const [previousStepProcessingDoneState, setPreviousStepProcessingDoneState] = useState(false); // eslint-disable-line prettier/prettier

  /**
   *
   */
  const resetStepButtons = () => {
    setNextStepValue(nextStepDefaultValue);
    setPreviousStepValue(previousStepDefaultValue);

    setNextStepProcessingState(false);
    setNextStepProcessingDoneState(false);

    setPreviousStepProcessingState(false);
    setPreviousStepProcessingDoneState(false);
  };

  /**
   *
   * @param goTo
   * @param direction
   */
  const saveStepHandler = (goTo: string, direction: string) => {
    if (direction === "next") {
      setNextStepValue("Saving changes..");
      setNextStepProcessingState(true);
    }

    if (direction === "previous") {
      setPreviousStepValue("Saving changes..");
      setPreviousStepProcessingState(true);
    }

    setTimeout(() => {
      if (direction === "next") {
        setNextStepProcessingState(false);
        setNextStepProcessingDoneState(true);
      }

      if (direction === "previous") {
        setPreviousStepProcessingState(false);
        setPreviousStepProcessingDoneState(true);
      }

      if (goTo) {
        setTimeout(() => {
          resetStepButtons();
          navigate(goTo);
        }, 800);
      } else {
        setTimeout(() => {
          resetStepButtons();
        }, 300);
      }
    }, 300);
  };

  /**
   * Function to control the next button step
   * @param props
   * @returns
   */
  const nextStepHandler = async () => {
    // let lastAddedId = "";

    const nextStep = parseInt(step) + 1;

    const goTo = `/submit/${materialID}/${nextStep}`;

    if (isSubmissionStep) {
      if (missingRequiredFields.length === 0) {
        setSubmissionStatus("submitting");

        // Allowed to submit
        const response = await submitMaterialMetadata({
          submissionState,
          dictionaryDataState,
          token,
          publishThisMaterial: true,
        });

        if (response?.status === 200) {
          setSubmissionStatus("submitted");
          navigate(`${goTo}`);
        } else {
          setSubmissionStatus("error");
        }
      } else {
        setSubmissionStatus("");
      }
    } else {
      saveStepHandler(goTo, "next");
    }
  };

  /**
   * Function to control the previous button step
   * @param props
   * @returns
   */
  const previousStepHandler = () => {
    const previousStep = parseInt(step) - 1;

    const goTo = `/submit/${materialID}/${previousStep}`;

    sendEventToGA({
      event_name: "previousStepHandler",
      current_step: step,
      previous_step: previousStep,
      go_to: goTo,
    });

    saveStepHandler(goTo, "previous");
  };

  useEffect(() => {
    if (triggerSaveMessage) {
      saveStepHandler("", "next");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSaveMessage]);

  useEffect(() => {
    setNextStepValue(nextStepDefaultValue);

    if (parseInt(step, 10) === 0) {
      setNextStepValue("Start");
    }

    if (isSubmissionStep) {
      setNextStepValue("Submit");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      <H1 innerContent={h1} additionalClasses="pb-5" />

      <div className="text-sm">
        {children}

        {!isConfirmationStep ? (
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6 md:col-span-6">
              {!isConfirmationStep ? (
                <Button
                  color={`${projectName.toLowerCase()}-blue`}
                  onClick={previousStepHandler}
                  isProcessingButton={previousStepProcessingState}
                  isProcessingDoneButton={previousStepProcessingDoneState}
                  disabled={
                    previousStepProcessingState
                      ? true
                      : !!previousStepProcessingDoneState
                  }
                  paddingClasses="pb-2 pt-1 px-3"
                >
                  <div className="inline-flex items-center align-middle">
                    <svg
                      className="fill-current w-5 h-4 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.4939 20.5644C11.1821 20.8372 10.7083 20.8056 10.4356 20.4939L3.43557 12.4939C3.18814 12.2111 3.18814 11.7889 3.43557 11.5061L10.4356 3.50613C10.7083 3.1944 11.1822 3.16281 11.4939 3.43557C11.8056 3.70834 11.8372 4.18216 11.5644 4.49388L5.65283 11.25L20 11.25C20.4142 11.25 20.75 11.5858 20.75 12C20.75 12.4142 20.4142 12.75 20 12.75L5.65283 12.75L11.5644 19.5061C11.8372 19.8179 11.8056 20.2917 11.4939 20.5644Z"
                      />
                    </svg>
                    <span>{previousStepValue}</span>
                  </div>
                </Button>
              ) : null}
            </div>

            <div className="col-span-6 md:col-span-6 text-right">
              {!isConfirmationStep ? (
                <Button
                  color={`${projectName.toLowerCase()}-blue`}
                  onClick={nextStepHandler}
                  isProcessingButton={nextStepProcessingState}
                  isProcessingDoneButton={nextStepProcessingDoneState}
                  disabled={
                    nextStepProcessingState
                      ? true
                      : !!nextStepProcessingDoneState
                  }
                  paddingClasses="pb-2 pt-1 px-3"
                >
                  <div className="inline-flex items-center align-middle">
                    <span>{nextStepValue}</span>
                    <svg
                      className="fill-current w-6 h-4 ml-2"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5061 3.43557C12.8178 3.16281 13.2917 3.19439 13.5644 3.50612L20.5644 11.5061C20.8119 11.7889 20.8119 12.2111 20.5644 12.4939L13.5644 20.4939C13.2917 20.8056 12.8178 20.8372 12.5061 20.5644C12.1944 20.2917 12.1628 19.8178 12.4356 19.5061L18.3472 12.75H4C3.58579 12.75 3.25 12.4142 3.25 12C3.25 11.5858 3.58579 11.25 4 11.25H18.3472L12.4356 4.49388C12.1628 4.18215 12.1944 3.70833 12.5061 3.43557Z"
                      />
                    </svg>
                  </div>
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default RightColumnContent;

RightColumnContent.defaultProps = {
  children: "",
  triggerSaveMessage: false,
  setSubmissionStatus: "",
  missingRequiredFields: "",
  isSubmissionStep: false,
  isConfirmationStep: false,
};
