/* eslint-disable prettier/prettier */
// IRIS Steps
import Step0 from "../components/submitElements/steps/iris/step0";
import Step1 from "../components/submitElements/steps/iris/step1";
import Step2 from "../components/submitElements/steps/iris/step2";
import Step3 from "../components/submitElements/steps/iris/step3";
import Step4 from "../components/submitElements/steps/iris/step4";
import Step5 from "../components/submitElements/steps/iris/step5";
import Step6 from "../components/submitElements/steps/iris/step6";
import Step7 from "../components/submitElements/steps/iris/step7";
import Step8 from "../components/submitElements/steps/iris/step8";
import Step9 from "../components/submitElements/steps/iris/step9";

// OASIS Steps
import OasisStep0 from "../components/submitElements/steps/oasis/oasisStep0";
import SummaryDetails from "../components/submitElements/steps/oasis/summaryDetails";
import PublicationDetails from "../components/submitElements/steps/oasis/publicationDetails";
import ResearchDetails from "../components/submitElements/steps/oasis/researchDetails";
import UploadStep from "../components/submitElements/steps/oasis/upload";
import Participants from "../components/submitElements/steps/oasis/participants";
import LicenceTermsNotes from "../components/submitElements/steps/oasis/licenceTermsNotes";
import Submission from "../components/submitElements/steps/oasis/submission";
import ConfirmationStep from "../components/submitElements/steps/oasis/confirmation";

const submissionSteps = {
  iris: [
    { step: 0, stepComponent: Step0, label: "IRIS Instrument Submission", submissionStep: false, confirmationStep: false },
    { step: 1, stepComponent: Step1, label: "Upload", submissionStep: false, confirmationStep: false },
    { step: 2, stepComponent: Step2, label: "Material details", submissionStep: false, confirmationStep: false },
    { step: 3, stepComponent: Step3, label: "Participants", submissionStep: false, confirmationStep: false },
    { step: 4, stepComponent: Step4, label: "Publications", submissionStep: false, confirmationStep: false },
    { step: 5, stepComponent: Step5, label: "Reference for your material", submissionStep: false, confirmationStep: false, updateDoiReference: true },
    { step: 6, stepComponent: Step6, label: "Acknowledgements", submissionStep: false, confirmationStep: false },
    { step: 7, stepComponent: Step7, label: "Licence, Communication & Terms and Conditions", submissionStep: false, confirmationStep: false },
    { step: 8, stepComponent: Step8, label: "Submission", submissionStep: true, confirmationStep: false },
    { step: 9, stepComponent: Step9, label: "Confirmation", submissionStep: false, confirmationStep: true },
  ],
  oasis: [
    { step: 0, stepComponent: OasisStep0, label: "OASIS Summary Submission", submissionStep: false, confirmationStep: false },
    { step: 1, stepComponent: SummaryDetails, label: "Summary details", submissionStep: false, confirmationStep: false },
    { step: 2, stepComponent: PublicationDetails, label: "Publication details", submissionStep: false, confirmationStep: false },
    { step: 3, stepComponent: ResearchDetails, label: "Summary creator", submissionStep: false, confirmationStep: false },
    { step: 4, stepComponent: UploadStep, label: "Upload", submissionStep: false, confirmationStep: false },
    { step: 5, stepComponent: Participants, label: "More details about the study", submissionStep: false, confirmationStep: false },
    { step: 6, stepComponent: LicenceTermsNotes, label: "Licence, Terms and Conditions, Notes", submissionStep: false, confirmationStep: false },
    { step: 7, stepComponent: Submission, label: "Submission", submissionStep: true, confirmationStep: false },
    { step: 8, stepComponent: ConfirmationStep, label: "Confirmation", submissionStep: false, confirmationStep: true },
  ],
};

export default submissionSteps;
