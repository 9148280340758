/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import clsx from "clsx";
import { isArray } from "lodash";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { getProjectName } from "../../../lib/functions";

let saveTimer: null | ReturnType<typeof setTimeout> = null;

interface RadioFieldProps {
  name: string;
  options: string[];
  additionalClasses?: string;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  triggerSaveMessageHandler?: any;
}

export default function RadioField(props: RadioFieldProps) {
  const submissionState = useContext(SubmissionState);
  const submissionDispatch = useContext(SubmissionDispatch);

  const projectName = getProjectName(true);

  const {
    name,
    options = [],
    additionalClasses,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
  } = props;

  const fieldValues = InputFieldsHelper.getFieldValues({
    referenceNumber,
    acknowledgementNumber,
    name,
    submissionState,
    projectName,
  });

  console.log("🚀 ~ InputField ~ fieldValues:", fieldValues);

  const onChangeHandler = InputFieldsHelper.getOnChangeHandler({
    referenceNumber,
    acknowledgementNumber,
    name,
    projectName,
  });

  console.log("🚀 ~ InputField ~ onChangeHandler:", onChangeHandler);


  let checkedValue = "";
  if (isArray(fieldValues)) {
    [checkedValue] = fieldValues;
  } else {
    checkedValue = fieldValues;
  }

  return (
    <>
      {options.map((v: any, i: any) => {
        const key = `${name}${i}`;

        const additionalMarginClass = i > 0 ? "ml-2" : "";

        return (
          <span key={key}>
            <input
              type="radio"
              id={key}
              name={name}
              checked={checkedValue === v}
              className={clsx(additionalClasses, additionalMarginClass)}
              onChange={(e) => {
                // Cancel current saveTimer when new changes are coming in before timeout passes
                clearTimeout(saveTimer);

                // Update state
                submissionDispatch({
                  type: onChangeHandler,
                  value: e.target.value,
                  fieldName: name,
                });

                // Trigger save after X milliseconds to avoid too many save requests
                // timer is canceled on new change event
                saveTimer = setTimeout(() => {
                  triggerSaveMessageHandler();
                }, 500);
              }}
              value={v}
            />
            {` ${v}`}
          </span>
        );
      })}
    </>
  );
}
