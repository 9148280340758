import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import useCopy from "use-copy";
import InputWrapper from "../../inputWrapper";
import SelectField from "../../fields/selectField";
import { infoBoxesContent } from "../../infoBoxesContent";

import { SubmissionState } from "../../../../context/SubmissionContext";
import CopyToClibboardIcon from "../../../htmlElements/copyToClibboardIcon";

interface StepProps {
  triggerSaveMessageHandler: any;
}

export default function Step5(props: StepProps) {
  const submissionState = useContext(SubmissionState) || "";

  const [doiReference, updateDoiReference] = useState<string>("");

  useEffect(() => {
    updateDoiReference(submissionState?.doi?.doiReference);
  }, [submissionState]);

  /**
   * Get the `introText` from the markdown files
   */
  const query = useStaticQuery(graphql`
    query {
      submissionTextQuery: allMarkdownRemark(
        filter: { frontmatter: { title: { glob: "Step 5 text" } } }
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
    }
  `);

  const submissionText = query.submissionTextQuery.edges[0].node.html;

  return (
    <>
      <div className="pb-5 mb-5 border-b border-b-gray-2">
        <div
          className="markdownText p0"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: submissionText,
          }}
        />
      </div>
      <InputWrapper
        title="Reference, including DOI, for this material"
        infoBoxContent={infoBoxesContent.doiTitleInfo}
        // required
      >
        <div className="pb-2">
          This DOI title has been formulated according to DataCite guidelines,
          and has used the information you have provided so far. To amend it,
          you must return to previous steps 2 (material details) and 4
          (publication details). Once you have pressed ‘Submit’ at the end of
          this upload process, this reference cannot be edited.
        </div>

        <div
          className="w-full py-2 pl-4 pr-10 relative"
          style={{ border: `1px dotted #aaa`, borderRadius: `8px` }}
        >
          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: doiReference,
            }}
          />
          <div className="absolute right-2 top-1">
            <CopyToClibboardIcon text={doiReference} />
          </div>
        </div>
      </InputWrapper>

      {/* Do not remove this unused select field, otherwise the classes won't be loaded that we need. */}
      <div className="hidden">
        <SelectField
          name="someField"
          options={[]}
          triggerSaveMessageHandler={() => {
            console.log("do nothing trigger");
          }}
        />
      </div>
    </>
  );
}
