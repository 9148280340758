import React, { useContext, useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { isEmpty } from "lodash";
import LoadingIcon from "../../../loadingIcon";
import InputWrapper from "../../inputWrapper";
import SelectField from "../../fields/selectField";
import { infoBoxesContent } from "../../infoBoxesContent";
import { DictionaryDataState } from "../../../../context/DictionaryDataContext";
import { SubmissionState } from "../../../../context/SubmissionContext";
import { step7options } from "../../../../data/step7options";
import TextareaField from "../../fields/textareaField";

interface StepProps {
  triggerSaveMessageHandler: any;
}

export default function OasisLicenceTermsNotesStep(props: StepProps) {
  const dictionaryDataState = useContext(DictionaryDataState) || {
    oasisSettingsRights: [],
  };
  const submissionState = useContext(SubmissionState) || {
    settings: { rights: { value: "" } },
  };

  const { triggerSaveMessageHandler } = props;

  const { licenceAgreement } = step7options;
  const [loaded, setLoaded] = useState(true);
  const [rights, setRights] = useState<any>([]);

  useEffect(() => {
    if (!isEmpty(dictionaryDataState)) {
      const { oasisSettingsRights } = dictionaryDataState;

      if (oasisSettingsRights) {
        const filteredRights = oasisSettingsRights
          .filter((item: any) => {
            if (submissionState.settings.rights) {
              if (
                (item.label && item.label.includes("4.0")) ||
                item.id === submissionState.settings.rights.value
              ) {
                return item;
              }
            } else if (item.label && item.label.includes("4.0")) {
              return item;
            } else {
              //
            }
          })
          .map((item: any) => {
            return {
              label: item.def,
              value: item.id,
              oldValue: item.label,
            };
          });

        setRights(filteredRights);
      }

      setLoaded(true);
    }
  }, [dictionaryDataState]);

  const { introText, ccLicenceText, termsAndConditionsText } =
    useStaticQuery(graphql`
      query {
        introText: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-submission-step-6" } }
        ) {
          html
        }

        ccLicenceText: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-cc-license-text" } }
        ) {
          html
        }

        termsAndConditionsText: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-terms-and-conditions-text" } }
        ) {
          html
        }
      }
    `);

  return loaded ? (
    <>
      {introText.html ? (
        <div className="pb-5 mb-5 border-b border-b-gray-2">
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: introText.html,
            }}
          />
        </div>
      ) : null}

      <InputWrapper
        title="Creative Commons Licence Selection"
        infoBoxContent={infoBoxesContent.creativeCommonsLicenceSelectionInfo}
        required
      >
        {ccLicenceText.html ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: ccLicenceText.html,
            }}
          />
        ) : null}

        <SelectField
          name="settingsLicenceAgreement"
          options={[
            {
              label:
                "I accept that the OASIS summary will be published under a CC BY-NC-SA license",
              value: "true",
            },
            {
              label:
                "I don't accept that the OASIS summary will be published under a CC BY-NC-SA license",
              value: "false",
            },
          ]}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          isMulti={false}
          required
        />
      </InputWrapper>

      <InputWrapper
        title="Terms and Conditions Declaration"
        infoBoxContent={infoBoxesContent.termsAndConditionsDeclarationInfo}
        required
      >
        {termsAndConditionsText.html ? (
          <div
            className="markdownText p0 mb-4"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: termsAndConditionsText.html,
            }}
          />
        ) : null}

        <SelectField
          name="settingsTermsAndConditions"
          options={[
            {
              label: "Yes, I agree to the terms and conditions.",
              value: "true",
            },
            {
              label: "No, I don't agree to the terms and conditions.",
              value: "false",
            },
          ]}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          isMulti={false}
          required
        />
      </InputWrapper>

      <InputWrapper
        title="Comments on the submission process"
        infoBoxContent={infoBoxesContent.commentsOnTheSubmissionProcessInfo}
      >
        <div className="pb-3">
          Lastly, and thank you for getting this far, please leave any comments
          about the submission form.
        </div>

        <TextareaField
          name="settingsComments"
          height={100}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}
