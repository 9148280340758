export default {
  latin: {
    journalName: "Studies in Second Language Acquisition",
    researchTitle:
      "How do Japanese children's digital app experiences develop their English proficiency?",
    researchText:
      "Language learning apps, particularly those that are game-based, are becoming a popular and valuable resource for second language learning, both as supplementary to language learning courses or as an extra-mural resource that can be used independently of a class or course. Research shows that game-based digital apps are beneficial because they provide learners with opportunities to manage their own learning process. They often include goal-oriented, authentic communication experiences, and they are motivating for learners. However, there has not been enough research focused on the benefits for younger children. This study investigated the use of a game-based language learning app by 7–8-year-old children in Japan. The results showed that the app was effective at improving the children's English language proficiency and also showed that certain choices they made in the app, such as number of activities they completed and prior activities they replayed, were positively associated with their learning.",
    whatResearchersDidText: `<ul class="ul">
    <li>Recruited one hundred and forty-eight 7-8-year-old children (and their families) in Japan who did not spend more than an hour a week outside of school learning English.</li>
    <li>Randomly assigned half of the children to a group that was asked to use the app for a minimum of 15-20 minutes every day, 6 days a week for 16 weeks. The other half of the children did not use the app but continued with their lives "business as usual", and were asked not to start any new language learning activities during the study.</li>
    <li>When dividing the children into two groups (app-users and non-users), ensured the two groups were similar in English proficiency and other characteristics such as age and gender.</li>
    <li>Measured the English proficiency of all 148 of the children at the beginning and end of the 16-week period using two different tests. One test was designed specifically to measure the language included in the app. The other test, called Eiken Junior, was a well-known commercial test widely used in Japan.</li>
    <li>Collected information about the children and families' experiences with the app through surveys, focus groups, and individual interviews.</li>
</ul>`,
    whatResearchersFoundText: `<ul>
    <li>The app-based test of English showed the group of children who used the app for 16 weeks improved significantly (19% improvement) compared to the group of children who had not used the app (4% improvement).</li>
    <li>The Eiken Junior test showed the group of children who had used the app for 16 weeks improved significantly more (44 points), reaching a higher Eiken level, than the group of children who had not used the app (15 points).</li>
    <li>Parents believed that the app had a positive impact on their children in areas such as vocabulary and pronunciation, as well as on their children's motivation and confidence in learning English.</li>
    <li>Children who used the app maintained high engagement during the 16 weeks, averaging 101 minutes per week.</li>
    <li>During app use, children spent their time in different ways. Some spent more time in exploration and free areas.</li>
    <li>How children spent their time was more important than the total time they used the app. The number of activities completed each week was the greatest predictor of improvement in English.</li>
    <li>Feedback from parents showed that the app was also effective in motivating children to use English in other situations and suggested that the self-directed nature of the app helped engage children.</li>
</ul>`,
    thingsToConsiderText: `<ul>
    <li>Digital game-based learning apps can be used effectively outside of the classroom to promote English language learning by children.</li>
    <li>How children spend time in an app can affect learning outcomes, so research-driven design of apps is important.</li>
    <li>Further research into children's decisions about their in-app play patterns can inform app designers.</li>
    <li>Further research in a wider variety of contexts and cultures is also warranted.</li>
</ul>`,
  },
  script: {
    journalName: "Studies in Second Language Acquisition",
    researchTitle:
      "日本の子どもたちのデジタルアプリ体験は、どのように英語力を向上させるのか？",
    researchText: `Chinese (Simplified): "快速的棕色狐狸跳过懒狗。"
Cantonese + Arabic + Punjabi: "! 快速的棕色狐狸跳过懒狗。! الثعلب البني السريع يقفز فوق الكلب الكسول. ! ਜਲਦੀ ਦੀਆਂ ਬੂਰੀਆਂ ਕੁੱਤਿਆਂ ਨੂੰ ਚਾਲੂ ਕਰਨਾ ਸੌਖਾ ਹੈ. !"
English (Latin): "The quick brown fox jumps over the lazy dog."
Yiddish (Hebrew): "דער שנעל ברוין פוקס שפּרינגט איבער דעם ליידיקן הונט."
Tifinagh (Berber): "ⴰⴽⵡⴽ ⴷⴷⴷⵉ ⴳⵉⵔⴰ ⵉⵎⴰⵣⴷⵉⵎ ⴷⴷⵓⵏⵉⵜ."
Tibetan (Tibetan): "མགྱོགས་པོ་གངས་སུ་ཡོད་པའི་སྦྲག་ཁྲིམས་མང་པོ་བཙུགས་ཡོད་པར་སྐྱོང་དགའ་རིགས།"
Sinhala (Sinhala): "ඉක්මන් දුඹුරු නරිය පිසදමන සුනඛයාට උඩින් පනින්නා."
Georgian (Georgian): "სწრაფი ყავისფერი მელა ხტომა ზარმაცი ძაღლის ზემოთ."
Hebrew (Hebrew): "השועל החום הקטן קופץ מעל הכלב העצלן."
Armenian (Armenian): "Արագ շագանակագույն աղվեսը ցատկում է ծույլ շան վրա։"
Khmer (Khmer): "ចចកពណ៌ត្នោតលោតលើឆ្កែខ្ជិល។"
Amharic (Geʻez): "ታናሽ ቡና ቆሻሻ ቁርጥ በልዑል ውስጥ ይንቀሳቀሳል።"
Bengali (Bengali): "দ্রুত বাদামী শিয়াল অলস কুকুরের উপর লাফ দেয়।"
Mandarin (Simplified Chinese): "快速的棕色狐狸跳过懒狗。"
Spanish (Latin): "El rápido zorro marrón salta sobre el perro perezoso."
Russian (Cyrillic): "Быстрая коричневая лисица прыгает через ленивую собаку."
Arabic (Arabic): "الثعلب البني السريع يقفز فوق الكلب الكسول."
Kannada (Kannada): "ತ್ವರಿತದ ಕಂದು ನರಿ ಆಲಸ್ಯದ ನಾಯಿಯ ಮೇಲೆ ಹಾರುತ್ತದೆ."
Japanese (Katakana/Hiragana): "速い茶色の狐は怠惰な犬の上を跳び越える。"
Hindi (Devanagari): "तेज़ भूरी लोमड़ी आलसी कुत्ते के ऊपर कूदती है।"
Gujarati (Gujarati): "ઝડપી ભૂરો સિયાળ આળસુ કૂતરાની ઉપર કૂદી જાય છે."
Punjabi (Gurmukhi): "ਜਲਦੀ ਦੀਆਂ ਬੂਰੀਆਂ ਕੁੱਤਿਆਂ ਨੂੰ ਚਾਲੂ ਕਰਨਾ ਸੌਖਾ ਹੈ."
Tamil (Tamil): "வேகமான பழுப்பு நரி சோம்பேறி நாயின் மீது குதிக்கிறது."
Lao (Lao): "ການເຊື່ອມຕົວໃນມັນນີ້ຄືພຽງເວລາແລະຈຸດກໍານົດ."
Thai (Thai): "สุนัขจิ้งจอกสีน้ำตาลกระโดดข้ามหมาขี้เกียจ."
Persian (Arabic): "روباه سریع قهوه‌ای از روی سگ تنبل می‌پرد."
Greek (Greek): "Η γρήγορη καφέ αλεπού πηδά πάνω από τον τεμπέλη σκύλο."
Burmese (Burmese): "အလျင်အမြန် သက်ဆိုင်၏ ဟု အဖြစ် သင့်ရိုဇာ ထင်၏ ထင်ကြီး သင့်၏"
Korean (Hangul): "빠른 갈색 여우가 게으른 개를 뛰어넘는다."
Telugu (Telugu): "వేగవంతమైన గోధుమ రంగు నక్క పులివేషం కుక్కను దాటుతుంది."`,
    whatResearchersDidText: `<ul>
    <li>Chinese (Simplified): "快速的棕色狐狸跳过懒狗。"</li>
    <li>Cantonese + Arabic + Punjabi: "! 快速的棕色狐狸跳过懒狗。! الثعلب البني السريع يقفز فوق الكلب الكسول. ! ਜਲਦੀ ਦੀਆਂ ਬੂਰੀਆਂ ਕੁੱਤਿਆਂ ਨੂੰ ਚਾਲੂ ਕਰਨਾ ਸੌਖਾ ਹੈ. !"</li>
    <li>English (Latin): "The quick brown fox jumps over the lazy dog."</li>
    <li>Yiddish (Hebrew): "דער שנעל ברוין פוקס שפּרינגט איבער דעם ליידיקן הונט."</li>
    <li>Tifinagh (Berber): "ⴰⴽⵡⴽ ⴷⴷⴷⵉ ⴳⵉⵔⴰ ⵉⵎⴰⵣⴷⵉⵎ ⴷⴷⵓⵏⵉⵜ."</li>
    <li>Tibetan (Tibetan): "མགྱོགས་པོ་གངས་སུ་ཡོད་པའི་སྦྲག་ཁྲིམས་མང་པོ་བཙུགས་ཡོད་པར་སྐྱོང་དགའ་རིགས།"</li>
    <li>Sinhala (Sinhala): "ඉක්මන් දුඹුරු නරිය පිසදමන සුනඛයාට උඩින් පනින්නා."</li>
    <li>Georgian (Georgian): "სწრაფი ყავისფერი მელა ხტომა ზარმაცი ძაღლის ზემოთ."</li>
    <li>Hebrew (Hebrew): "השועל החום הקטן קופץ מעל הכלב העצלן."</li>
    <li>Armenian (Armenian): "Արագ շագանակագույն աղվեսը ցատկում է ծույլ շան վրա։"</li>
    <li>Khmer (Khmer): "ចចកពណ៌ត្នោតលោតលើឆ្កែខ្ជិល។"</li>
    <li>Amharic (Geʻez): "ታናሽ ቡና ቆሻሻ ቁርጥ በልዑል ውስጥ ይንቀሳቀሳል።"</li>
    <li>Bengali (Bengali): "দ্রুত বাদামী শিয়াল অলস কুকুরের উপর লাফ দেয়।"</li>
    <li>Mandarin (Simplified Chinese): "快速的棕色狐狸跳过懒狗。"</li>
    <li>Spanish (Latin): "El rápido zorro marrón salta sobre el perro perezoso."</li>
    <li>Russian (Cyrillic): "Быстрая коричневая лисица прыгает через ленивую собаку."</li>
    <li>Arabic (Arabic): "الثعلب البني السريع يقفز فوق الكلب الكسول."</li>
    <li>Kannada (Kannada): "ತ್ವರಿತದ ಕಂದು ನರಿ ಆಲಸ್ಯದ ನಾಯಿಯ ಮೇಲೆ ಹಾರುತ್ತದೆ."</li>
    <li>Japanese (Katakana/Hiragana): "速い茶色の狐は怠惰な犬の上を跳び越える。"</li>
    <li>Hindi (Devanagari): "तेज़ भूरी लोमड़ी आलसी कुत्ते के ऊपर कूदती है।"</li>
    <li>Gujarati (Gujarati): "ઝડપી ભૂરો સિયાળ આળસુ કૂતરાની ઉપર કૂદી જાય છે."</li>
    <li>Punjabi (Gurmukhi): "ਜਲਦੀ ਦੀਆਂ ਬੂਰੀਆਂ ਕੁੱਤਿਆਂ ਨੂੰ ਚਾਲੂ ਕਰਨਾ ਸੌਖਾ ਹੈ."</li>
    <li>Tamil (Tamil): "வேகமான பழுப்பு நரி சோம்பேறி நாயின் மீது குதிக்கிறது."</li>
    <li>Lao (Lao): "ການເຊື່ອມຕົວໃນມັນນີ້ຄືພຽງເວລາແລະຈຸດກໍານົດ."</li>
    <li>Thai (Thai): "สุนัขจิ้งจอกสีน้ำตาลกระโดดข้ามหมาขี้เกียจ."</li>
    <li>Persian (Arabic): "روباه سریع قهوه‌ای از روی سگ تنبل می‌پرد."</li>
    <li>Greek (Greek): "Η γρήγορη καφέ αλεπού πηδά πάνω από τον τεμπέλη σκύλο."</li>
    <li>Burmese (Burmese): "အလျင်အမြန် သက်ဆိုင်၏ ဟု အဖြစ် သင့်ရိုဇာ ထင်၏ ထင်ကြီး သင့်၏"</li>
    <li>Korean (Hangul): "빠른 갈색 여우가 게으른 개를 뛰어넘는다."</li>
    <li>Telugu (Telugu): "వేగవంతమైన గోధుమ రంగు నక్క పులివేషం కుక్కను దాటుతుంది."</li>
</ul>`,
    whatResearchersFoundText: "",
    thingsToConsiderText: "",
  },
  special: {
    journalName: "🚀💬 Studies in Second Language Acquisition 快速",
    researchTitle:
      "📱✨ Exploring the Impact of Emojis and Symbols in Digital Learning 💡🎓",
    researchText:
      "💬 In digital education apps, the use of emojis 🌟, special characters like @, #, $, and unique symbols (✓✗) is increasing. This study examines how these elements influence engagement 🧐, learning efficiency 📈, and overall user satisfaction 😊. While some argue that emojis boost motivation 🎯, others suggest they may distract users 🤔.",
    whatResearchersDidText: `<ul>
    <li>🎮 Tested an app with 200 participants, half using a version filled with emojis 😎 and special characters ⚡, and the other half using a text-only version ✍️.</li>
    <li>📊 Measured engagement levels 📱, learning outcomes 🎯, and user feedback 📝 through in-app tracking 📍 and post-use surveys 💬.</li>
    <li>🚀 Collected data on how often users interacted with emojis vs. text, analyzing reading speed 🕑 and comprehension 📚.</li>
</ul>`,
    whatResearchersFoundText: "!@#$%^&*()_+`~-=[]{}'<>,./?|",
    thingsToConsiderText: "1234567890",
  },
};
