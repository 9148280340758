/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { concat, isEmpty, range } from "lodash";
import InputWrapper from "../../inputWrapper";
import SelectField from "../../fields/selectField";
import NewAuthorForm from "../../fields/newAuthorForm";
import HierarchicalSelectField from "../../fields/hierarchicalSelectField";
import { DictionaryDataState } from "../../../../context/DictionaryDataContext";
import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../../context/SubmissionContext";
import LoadingIcon from "../../../loadingIcon";
import InputField from "../../fields/inputField";
import NewOtherForm from "../../fields/newOtherForm";

const thisYear = new Date().getFullYear();

const issues = [
  "Please choose",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "Supplement 1",
  "Supplement 2",
  "Supplement 3",
  "Supplement 4",
  "no issue",
];

interface StepProps {
  triggerSaveMessageHandler: any;
  materialSettings: any;
}

export default function OasisPublicationDetailsStep(props: StepProps) {
  const dictionaryDataState: any = useContext(DictionaryDataState) || [];
  const submissionState: any = useContext(SubmissionState) || "";
  const submissionDispatch: any = useContext(SubmissionDispatch) || "";

  const { triggerSaveMessageHandler, materialSettings } = props;
  const [loaded, setLoaded] = useState(false);

  /* eslint-disable */
  const [publicationAuthor, setPublicationAuthor] = useState<object>([]);
  const [publicationJournalName, setPublicationJournalName] = useState<object>([]);
  const [publicationParticipantType, setPublicationParticipantType] = useState<object>([]);
  const [publicationGeneralResearchArea, setPublicationGeneralResearchArea] = useState<object>([]);
  const [publicationOfLikelyInterestTo, setPublicationOfLikelyInterestTo] = useState<object>([]);
  /* eslint-enable */

  const setDictionaryData = (updateLoaded = false) => {
    if (!isEmpty(dictionaryDataState)) {
      /* eslint-disable */

      setPublicationAuthor(dictionaryDataState.oasisAuthors);
      setPublicationJournalName(dictionaryDataState.oasisPublicationJournals);
      setPublicationParticipantType(dictionaryDataState.oasisPublicationParticipantTypes);
      setPublicationGeneralResearchArea(dictionaryDataState.oasisPublicationGeneralResearchAreas);
      setPublicationOfLikelyInterestTo(dictionaryDataState.oasisPublicationOfLikelyInterestTo);

      /* eslint-enable */

      // Set loaded to `true` so that the content is shown to the user
      if (updateLoaded) {
        setLoaded(true);
      }
    }
  };

  // useLayoutEffect(() => {
  //   console.log("hereereer");
  //   setTimeout(() => {
  //     window.scrollTo(0, 0); // Reset scroll to the top
  //   }, 500);
  // }, []);

  /**
   * We need to set the new values from the dictionaryDataState once they are updated - they
   * can be updated from for example `newOtherForm`
   */
  useEffect(() => {
    setDictionaryData(true);
  }, [dictionaryDataState]);

  const { introText, infoBoxesContent, subTitlesContent } =
    useStaticQuery(graphql`
      query {
        introText: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-submission-step-2" } }
        ) {
          html
        }

        infoBoxesContent: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-info-boxes" } }
        ) {
          frontmatter {
            authorOfPublication
            titleOfPublication
            publicationDate
            journalOfPublication
            issue
            volume
            pages
            identifier
            areaOfResearch
            participantType
            ofLikelyInterestTo
            summaryOriginalArticleOpenAccess
          }
        }

        subTitlesContent: markdownRemark(
          frontmatter: { pageId: { eq: "OASIS-subtitles-content" } }
        ) {
          frontmatter {
            authorOfPublication
            titleOfPublication
            publicationDate
            journalOfPublication
            issue
            volume
            pages
            identifier
            areaOfResearch
            participantType
            ofLikelyInterestTo
            summaryOriginalArticleOpenAccess
          }
        }
      }
    `);

  const referenceNumber = 0;

  // Create 1 publication at the start
  useEffect(() => {
    if (isEmpty(submissionState.publication)) {
      submissionDispatch({ type: "addAnotherPublicationHandler" });
      submissionDispatch({
        type: "publicationChangeHandler",
        value: "Journal Name",
        fieldName: "publicationType",
        referenceNumber,
      });
    }
  }, []);

  return loaded ? (
    <>
      {introText.html ? (
        <div
          className="markdownText"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: introText.html,
          }}
        />
      ) : null}

      <InputWrapper
        title="Author(s) of publication"
        infoBoxContent={infoBoxesContent.frontmatter.authorOfPublication}
        required
      >
        {subTitlesContent.frontmatter.authorOfPublication ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.authorOfPublication,
            }}
          />
        ) : null}

        <SelectField
          name="publicationAuthor"
          options={publicationAuthor}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          required
          zIndex={6000}
          materialSettings={materialSettings}
          doiField
          referenceNumber={referenceNumber}
        />
        <NewAuthorForm
          authors={publicationAuthor}
          setAuthors={setPublicationAuthor}
          fieldName="publicationAuthor"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          doiField
          referenceNumber={referenceNumber}
        />
      </InputWrapper>

      <InputWrapper
        title="Title of publication"
        infoBoxContent={infoBoxesContent.frontmatter.titleOfPublication}
        required
      >
        {subTitlesContent.frontmatter.titleOfPublication ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.titleOfPublication,
            }}
          />
        ) : null}

        <InputField
          name="publicationTitle"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber} // important
          doiField
        />
      </InputWrapper>

      <InputWrapper
        title="Year of publication"
        infoBoxContent={infoBoxesContent.frontmatter.publicationDate}
        required
      >
        {subTitlesContent.frontmatter.publicationDate ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.publicationDate,
            }}
          />
        ) : null}

        <SelectField
          name="publicationDate"
          options={concat(
            ["Please choose", "In press"],
            range(thisYear, 1949)
          ).map((item) => {
            return {
              value: item,
              label: item,
            };
          })}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
          isMulti={false}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
        />
      </InputWrapper>

      <InputWrapper
        title="Journal of publication"
        infoBoxContent={infoBoxesContent.frontmatter.journalOfPublication}
        required
      >
        {subTitlesContent.frontmatter.journalOfPublication ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.journalOfPublication,
            }}
          />
        ) : null}

        <SelectField
          name="publicationJournalName"
          options={publicationJournalName.map((item: any) => {
            return { value: item.value, label: item.label };
          })}
          referenceNumber={referenceNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
        />
        <span className="pt-2 inline-block">
          <NewOtherForm
            title="journal of publication"
            inputName="publicationJournalNameNew"
            fieldName="publicationJournalName"
            dictionaryStateName="oasisPublicationJournals"
            triggerSaveMessageHandler={triggerSaveMessageHandler}
            materialSettings={materialSettings}
            referenceNumber={referenceNumber}
            options={publicationJournalName.map((item: any) => {
              return { value: item.value, label: item.label };
            })}
          />
        </span>
      </InputWrapper>

      <div className="pb-3 mb-3 border-b border-b-gray-2">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4 md:col-span-4">
            <InputWrapper
              title="Volume"
              infoBoxContent={infoBoxesContent.frontmatter.volume}
              hideBorder
              hidePadding
              hideMargin
              titlePadding="pb-5x"
            >
              {subTitlesContent.frontmatter.volume ? (
                <div
                  className="markdownText p0"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: subTitlesContent.frontmatter.volume,
                  }}
                />
              ) : null}

              <InputField
                name="publicationVolume"
                placeholder="Volume"
                referenceNumber={referenceNumber}
                triggerSaveMessageHandler={triggerSaveMessageHandler}
                additionalClasses="mt-5"
              />
            </InputWrapper>
          </div>
          <div className="col-span-4 md:col-span-4">
            <InputWrapper
              title="Issue"
              infoBoxContent={infoBoxesContent.frontmatter.issue}
              hideBorder
              hidePadding
              hideMargin
              titlePadding="pb-5x"
            >
              {subTitlesContent.frontmatter.issue ? (
                <div
                  className="markdownText p0"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: subTitlesContent.frontmatter.issue,
                  }}
                />
              ) : null}

              <SelectField
                name="publicationIssue"
                options={issues.map((item) => {
                  return { value: item, label: item };
                })}
                referenceNumber={referenceNumber}
                isMulti={false}
                triggerSaveMessageHandler={triggerSaveMessageHandler}
                materialSettings={materialSettings}
                additionalClasses="mt-5"
              />
            </InputWrapper>
          </div>

          <div className="col-span-4 md:col-span-4">
            <InputWrapper
              title="Pages"
              infoBoxContent={infoBoxesContent.frontmatter.pages}
              hideBorder
              hidePadding
              hideMargin
              titlePadding="pb-1x"
            >
              {subTitlesContent.frontmatter.pages ? (
                <div
                  className="markdownText p0"
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: subTitlesContent.frontmatter.pages,
                  }}
                />
              ) : null}

              <div className="grid grid-cols-12 gap-4 mt-1">
                <div className="col-span-6 md:col-span-6">
                  <div className="text-xs">From:</div>
                  <InputField
                    name="publicationPageFrom"
                    placeholder="From"
                    referenceNumber={referenceNumber}
                    triggerSaveMessageHandler={triggerSaveMessageHandler}
                  />
                </div>
                <div className="col-span-6 md:col-span-6">
                  <div className="text-xs">To:</div>
                  <InputField
                    name="publicationPageTo"
                    placeholder="To"
                    referenceNumber={referenceNumber}
                    triggerSaveMessageHandler={triggerSaveMessageHandler}
                  />
                </div>
              </div>
            </InputWrapper>
          </div>
        </div>
      </div>

      <InputWrapper
        title="Publication identifier (DOI)"
        infoBoxContent={infoBoxesContent.frontmatter.identifier}
        customClasses="addAnotherIdentifierLink"
      >
        {subTitlesContent.frontmatter.identifier ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.identifier,
            }}
          />
        ) : null}
        <InputField
          name="publicationIdentifier"
          referenceNumber={referenceNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          placeholder="https://doi.org/"
        />
      </InputWrapper>

      <InputWrapper
        title="Participant type"
        infoBoxContent={infoBoxesContent.frontmatter.participantType}
        required
      >
        {subTitlesContent.frontmatter.participantType ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.participantType,
            }}
          />
        ) : null}
        <HierarchicalSelectField
          options={publicationParticipantType}
          inputName="publicationParticipantType"
          irisMaterialSection="publication"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          referenceNumber={referenceNumber}
          hideWrapper
        />

        <NewOtherForm
          title="participant type"
          inputName="publicationParticipantTypeNew"
          fieldName="publicationParticipantType"
          dictionaryStateName="oasisPublicationParticipantTypes"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
          options={publicationParticipantType.map((item: any) => {
            return { value: item.value, label: item.label };
          })}
        />
      </InputWrapper>

      <InputWrapper
        title="Area of research"
        infoBoxContent={infoBoxesContent.frontmatter.areaOfResearch}
        required
      >
        {subTitlesContent.frontmatter.areaOfResearch ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.areaOfResearch,
            }}
          />
        ) : null}
        <HierarchicalSelectField
          options={publicationGeneralResearchArea}
          inputName="publicationGeneralResearchArea"
          irisMaterialSection="publication"
          inlineNoteText=""
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          referenceNumber={referenceNumber}
          hideWrapper
        />
        <NewOtherForm
          title="area of research"
          inputName="publicationGeneralResearchAreaNew"
          fieldName="publicationGeneralResearchArea"
          dictionaryStateName="oasisPublicationGeneralResearchAreas"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
          options={publicationGeneralResearchArea.map((item: any) => {
            return { value: item.value, label: item.label };
          })}
        />
      </InputWrapper>

      <InputWrapper
        title="Of likely interest to ..."
        infoBoxContent={infoBoxesContent.frontmatter.ofLikelyInterestTo}
        required
      >
        {subTitlesContent.frontmatter.ofLikelyInterestTo ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.ofLikelyInterestTo,
            }}
          />
        ) : null}
        <HierarchicalSelectField
          options={publicationOfLikelyInterestTo}
          inputName="publicationOfLikelyInterestTo"
          irisMaterialSection="publication"
          inlineNoteText="Please select the likely audiences for your summary."
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          referenceNumber={referenceNumber}
          hideWrapper
        />
        <NewOtherForm
          title="group"
          inputName="publicationOfLikelyInterestToNew"
          fieldName="publicationOfLikelyInterestTo"
          dictionaryStateName="oasisPublicationOfLikelyInterestTo"
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          materialSettings={materialSettings}
          referenceNumber={referenceNumber}
          options={publicationOfLikelyInterestTo.map((item: any) => {
            return { value: item.value, label: item.label };
          })}
        />
      </InputWrapper>

      <InputWrapper
        title="IRIS URLs"
        infoBoxContent={infoBoxesContent.frontmatter.publicationIrisUrls}
        customClasses="addAnotherIdentifierLink"
      >
        {subTitlesContent.frontmatter.publicationIrisUrls ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.publicationIrisUrls,
            }}
          />
        ) : null}
        <InputField
          name="publicationIrisUrls"
          referenceNumber={referenceNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          placeholder="https://iris-database.org/details/..."
          isMulti
        />
      </InputWrapper>

      <InputWrapper
        title="External URLs"
        infoBoxContent={infoBoxesContent.frontmatter.publicationExternalUrls}
        customClasses="addAnotherIdentifierLink"
      >
        {subTitlesContent.frontmatter.publicationExternalUrls ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: subTitlesContent.frontmatter.publicationExternalUrls,
            }}
          />
        ) : null}
        <InputField
          name="publicationExternalUrls"
          referenceNumber={referenceNumber}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          placeholder="https://osf.io/..."
          isMulti
        />
      </InputWrapper>

      <InputWrapper
        title="Open Access"
        infoBoxContent={
          infoBoxesContent.frontmatter.summaryOriginalArticleOpenAccess
        }
        required
      >
        {subTitlesContent.frontmatter.summaryOriginalArticleOpenAccess ? (
          <div
            className="markdownText p0"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                subTitlesContent.frontmatter.summaryOriginalArticleOpenAccess,
            }}
          />
        ) : null}
        <SelectField
          name="summaryOriginalArticleOpenAccess"
          options={[
            { label: "Yes", value: "true" },
            { label: "No", value: "false" },
          ]}
          triggerSaveMessageHandler={triggerSaveMessageHandler}
          isMulti={false}
        />
      </InputWrapper>
    </>
  ) : (
    <div className="text-center p-5">
      <LoadingIcon />
    </div>
  );
}
