/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getProjectName } from "../../../../lib/functions";

export default function Step0() {
  const projectName = getProjectName(true);

  const query = useStaticQuery(graphql`
    query {
      irisText: markdownRemark(
        frontmatter: { pageId: { eq: "IRIS-submission-step-0" } }
      ) {
        frontmatter {
          title
        }
        html
      }

      oasisText: markdownRemark(
        frontmatter: { pageId: { eq: "OASIS-submission-step-0" } }
      ) {
        frontmatter {
          title
        }
        html
      }
    }
  `);

  let htmlText = "";

  try {
    if (projectName === "IRIS") {
      htmlText = query?.irisText?.html;
    }

    if (projectName === "OASIS") {
      htmlText = query?.oasisText?.html;
    }
  } catch (error) {
    console.log("🚀 ~ error:", error);
  }

  return (
    <div
      className="markdownText"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: htmlText,
      }}
    />
  );
}
