/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useContext } from "react";
import clsx from "clsx";
import { isArray, isNumber } from "lodash";
import InputFieldsHelper from "../../../helpers/inputFieldsHelper";

import {
  SubmissionState,
  SubmissionDispatch,
} from "../../../context/SubmissionContext";

import { getProjectName } from "../../../lib/functions";

let saveTimer: null | ReturnType<typeof setTimeout> = null;

interface TextareaFieldProps {
  name: string;
  placeholder?: string;
  height?: number;
  isMulti?: boolean;
  additionalClasses?: string;
  referenceNumber?: number;
  acknowledgementNumber?: number;
  triggerSaveMessageHandler?: any;
}
export default function TextareaField(props: TextareaFieldProps) {
  const submissionState = useContext(SubmissionState) || "";
  const submissionDispatch = useContext(SubmissionDispatch) || "";

  const projectName = getProjectName(true);

  const {
    name,
    placeholder,
    isMulti,
    height,
    additionalClasses,
    referenceNumber,
    acknowledgementNumber,
    triggerSaveMessageHandler,
  } = props;

  const fieldValues = InputFieldsHelper.getFieldValues({
    referenceNumber,
    acknowledgementNumber,
    name,
    submissionState,
    projectName,
  });

  // console.log("🚀 ~ InputField ~ fieldValues:", fieldValues);

  const onChangeHandler = InputFieldsHelper.getOnChangeHandler({
    referenceNumber,
    acknowledgementNumber,
    name,
    projectName,
  });

  // console.log("🚀 ~ InputField ~ onChangeHandler:", onChangeHandler);

  return (
    // Why do we itterate fieldValues? Do we expect to see array with more than one element?
    <>
      {fieldValues.map((v: any, i: any) => {
        const key = name;

        const additionalMarginClass = i > 0 ? "mt-2" : "";

        return (
          <textarea
            name={name}
            id={key}
            key={key}
            placeholder={placeholder || ""}
            className={clsx(
              "w-full css-1s2u09g-control p-2",
              additionalClasses,
              additionalMarginClass
            )}
            style={{ height: `${height || 40}px` }}
            onChange={(e) => {
              // Cancel current saveTimer when new changes are coming in before timeout passes
              clearTimeout(saveTimer);

              submissionDispatch({
                type: onChangeHandler,
                value: e.target.value,
                fieldName: name,
                referenceNumber,
                acknowledgementNumber,
              });

              // Trigger save after X milliseconds to avoid too many save requests
              // timer is canceled on new change event
              saveTimer = setTimeout(() => {
                triggerSaveMessageHandler();
              }, 500);
            }}
            value={v}
          >
            {v}
          </textarea>
        );
      })}
    </>
  );
}
